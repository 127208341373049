import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ANONYMOUS_USER_IMAGES,
  GET_IMAGES_OF_GROUP,
  GET_ALL_IMAGES_OF_GROUP,
} from "redux-store/sagas/saga-actions";
import AnonymousFolderImages from "./anonymousFolderImages";
import EmptyAnonymousGallery from "./emptyAnonymousGallery";
import { HeaderTemplate } from "views/components/modals/components/HeaderTemplate";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";
import Spinner from "views/components/loader/Spinner";
import Paginator from "views/gallary/gallaryPage/components/Paginator";
import usePagination from "hooks/usePagination";
import { IMAGES_PER_PAGE } from "views/gallary/gallaryPage/components/FolderImages";
import { allImagesType } from "views/routes/GalleryRoute";
import { clearFolderImages } from "redux-store/slices/folderImages";
import classNames from "classnames";
import GalleryHeaderButton from "views/gallary/gallaryPage/components/GalleryHeaderButton";
import { scroll, checkArrows } from "views/gallary/gallaryPage/GallaryView";
import LinkShareModal from "views/components/modals/LinkShareModal";
import FolderVideos from "views/gallary/gallaryPage/components/FolderVideos";
import { ERROR_TYPES_404 } from "views/notFound/NotFound";
import { clearAnonymousUserData } from "redux-store/slices/anonymous";
import { ssProxy } from "utils/helpers/localstorage";
import useAnonymousGalleryRouteManager from "hooks/useAnonymousGalleryRouteManager";

const INFOTYPE = {
  AVATAR_NOT_PROCESSED: "AVATAR_NOT_PROCESSED",
  NO_MATCHING_PHOTOS: "NO_MATCHING_PHOTOS",
  ZERO_PHOTOS: "ZERO_PHOTOS",
  NONE: "NONE",
};

const AnonymouseGalllery = () => {
  useAnonymousGalleryRouteManager();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const pageNumberFromURL = Number(params.get("page"));
  const scrollRef = useRef();
  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [infoType, setInfoType] = useState(INFOTYPE.NONE);

  const { images, anonymousUser, loading, videos } = useSelector(
    (state) => state.anonymous
  );

  const { totalCount, imagesLoading, allVideosCount } = useSelector(
    (state) => state.folderImages
  );

  const { group, webSettings, user } = anonymousUser || {};

  const { isAvatarProcessed, isResultsFound, allPicsAccess, error } =
    user || {};

  const { colorMode, font, grid, padding, photoSize } =
    webSettings?.design || {};

  const _folder = params.get("folderId");
  const groupId = params.get("groupId");
  const userId = params.get("anonymousUserId");

  const adminToken = ssProxy.getItem("adminToken");
  const fGroupCode = ssProxy.getItem("fGroupCode");

  useEffect(() => {
    if (_folder !== "my-photos")
      dispatch({
        type: GET_ANONYMOUS_USER_IMAGES,
        payload: {
          userId,
          groupId,
        },
      });
  }, []);

  useEffect(() => {
    if (anonymousUser?.hasError)
      navigate("/not-found", {
        replace: true,
        state: {
          ...(anonymousUser?.anonymousDisabled
            ? { type: ERROR_TYPES_404.ANONYMOUS_DISABLED }
            : {
                type: ERROR_TYPES_404.INVALID_ANONYMOUS_USER,
                data: {
                  groupId,
                  fGroupCode,
                  adminToken,
                },
              }),
        },
      });

    if (error?.includes("zero images")) setInfoType(INFOTYPE.ZERO_PHOTOS);
    else if (!isAvatarProcessed && typeof isAvatarProcessed == "boolean")
      setInfoType(INFOTYPE.AVATAR_NOT_PROCESSED);
    else if (!isResultsFound && typeof isResultsFound == "boolean")
      setInfoType(INFOTYPE.NO_MATCHING_PHOTOS);
    else setInfoType(INFOTYPE.NONE);

    if (state?.newUser && group) {
      setShowLinkModal(true);
      window.history.replaceState(null, "");
    }
  }, [anonymousUser]);

  useEffect(() => {
    // const token = lsProxy.getItem("token");
    // if (!token) {
    //   navigate("/auth/login");
    // }
    dispatch(clearFolderImages());
  }, [_folder]);

  useEffect(() => {
    // if (joingroupLoading) return;

    const ScrollWidth = scrollRef.current.childNodes[1]?.scrollWidth;
    const ClientWidth = scrollRef.current.childNodes[1]?.clientWidth;
    if (ClientWidth <= ScrollWidth) {
      setShowArrows((state) => ({ ...state, right: true }));
    }
  }, [group]);

  useEffect(() => {
    if (!groupId || !userId || !_folder || !pageNumberFromURL) return;

    if (_folder === "my-photos" && userId !== groupId) {
      dispatch({
        type: GET_ANONYMOUS_USER_IMAGES,
        payload: {
          userId,
          groupId,
        },
      });
    } else if (_folder === "all-photos") {
      dispatch({
        type: GET_ALL_IMAGES_OF_GROUP,
        groupID: groupId,
        page: pageNumberFromURL,
        limit: IMAGES_PER_PAGE,
        imageType: allImagesType.DATE,
        anonymoususerid: userId,
      });
    } else if (_folder === "all-videos") {
      // do nothing
    } else {
      dispatch({
        type: GET_IMAGES_OF_GROUP,
        folderId: _folder,
        page: pageNumberFromURL,
        limit: IMAGES_PER_PAGE,
        anonymoususerid: userId,
      });
    }
  }, [_folder, groupId, pageNumberFromURL, userId]);

  useEffect(() => {
    // if (joingroupLoading) return;

    const scrollEl = scrollRef.current.childNodes[1];
    const interval = setInterval(() => {
      checkArrows(scrollEl, showArrows, setShowArrows);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  });

  const totalImagesCount =
    _folder === "my-photos"
      ? images.length
      : _folder === "all-videos"
      ? videos?.length
      : totalCount;

  const { totalPages } = usePagination({
    totalCount: totalImagesCount,
    perPageCount: IMAGES_PER_PAGE,
    currentPage: Number(pageNumberFromURL),
  });

  const handlePageChange = (goToPage, totalPages) => {
    if (
      !(goToPage <= 0) &&
      !(goToPage > totalPages) &&
      goToPage !== pageNumberFromURL
    ) {
      window.scroll(0, 0);

      navigate(modifyAnonymousGallaryRoute({ pageNo: goToPage }, params));
      // dispatch(setFoldersPage({ page: goToPage, totalPages }));
    }
  };

  const renderGallery = () => {
    if (_folder === "all-videos")
      return <FolderVideos folderId={"all-videos"} />;
    return <AnonymousFolderImages />;
  };

  return (
    <div
      className={classNames(
        "gallaryPage",
        colorMode === 1 ? "light" : "dark",
        photoSize === 1 ? "sizeLarge" : "sizeSmall",
        grid === 1 ? "horizontal" : "vertical",
        padding === 1 ? "paddingLarge" : "paddingSmall",
        font === 1 ? "fontSerif" : "fontSans"
      )}
    >
      <HeaderTemplate />

      <div id="gallery-folders-list">
        <div className="gallary-header-container" ref={scrollRef}>
          <GalleryHeaderButton
            scroll={scroll}
            scrollRef={scrollRef}
            setShowArrows={setShowArrows}
            showArrows={showArrows}
            type="left"
            colorMode={colorMode}
          />
          <Tabs
            activeKey={_folder}
            className="d-flex flex-nowrap w-100 folder"
            onSelect={(_folderId) => {
              navigate(
                modifyAnonymousGallaryRoute(
                  {
                    folderId: _folderId,
                    pageNo: 1,
                  },
                  params
                )
              );
            }}
          >
            {userId !== groupId && (
              <Tab
                eventKey="my-photos"
                title={
                  <span className="group-top-text-wrapper font-14">
                    {_folder === "my-photos" && (
                      <span className="group-top-text">
                        {totalImagesCount}&nbsp;Photos
                      </span>
                    )}
                    <span>My Photos</span>
                  </span>
                }
              />
            )}
            <Tab
              eventKey="all-photos"
              title={
                <span className="group-top-text-wrapper font-14">
                  {_folder === "all-photos" && (
                    <span className="group-top-text">
                      {totalImagesCount}&nbsp;Photos
                    </span>
                  )}
                  <span>All Photos</span>
                </span>
              }
            />

            {videos?.length > 0 && (
              <Tab
                eventKey="all-videos"
                title={
                  <span className="group-top-text-wrapper font-14">
                    {_folder === "all-videos" && (
                      <span className="group-top-text">
                        {allVideosCount}&nbsp;Videos
                      </span>
                    )}
                    <span>All Videos</span>
                  </span>
                }
              />
            )}

            {group?.folders &&
              group?.folders.length > 0 &&
              group?.folders?.map((items, i) => {
                return (
                  <Tab
                    key={i}
                    eventKey={items?.groupFolder}
                    title={
                      <span className="group-top-text-wrapper font-14">
                        {items.groupFolder === _folder && (
                          <span className="group-top-text">
                            {totalCount}&nbsp;Photos
                          </span>
                        )}
                        <span>{items?.folderPrefix || "No-Name"}</span>
                      </span>
                    }
                  />
                );
              })}
          </Tabs>
          <GalleryHeaderButton
            scroll={scroll}
            scrollRef={scrollRef}
            setShowArrows={setShowArrows}
            showArrows={showArrows}
            type="right"
            colorMode={colorMode}
          />
        </div>

        {!totalImagesCount ? (
          <div className="d-flex justify-content-center p-20">
            <EmptyAnonymousGallery />
          </div>
        ) : (
          renderGallery()
        )}
      </div>
      {/* in case of all-videos, we'll use pagination of FolderVideos */}
      {_folder !== "all-videos" && (
        <>
          <Paginator
            currentPage={Number(pageNumberFromURL)}
            totalCount={totalImagesCount}
            perPageCount={IMAGES_PER_PAGE}
            onPageChange={(goToPage, totalPages) => {
              handlePageChange(goToPage, totalPages);
            }}
          />

          <div className="d-flex justify-content-center gallary-footer">
            {pageNumberFromURL > 1 && (
              <div
                onClick={() =>
                  handlePageChange(pageNumberFromURL - 1, totalPages)
                }
                className="prev-wrapper-footer font-bold font-20 cursor-pointer"
              >
                <img
                  className="prev-btn-footer"
                  src="/assets/images/icons/back-arrow.png"
                  alt="prev-arrow"
                />
                Previous
              </div>
            )}
            {pageNumberFromURL > 1 && pageNumberFromURL < totalPages && (
              <div className="separator-footer" />
            )}
            {totalPages > pageNumberFromURL && totalPages > 1 && (
              <div
                onClick={() =>
                  handlePageChange(pageNumberFromURL + 1, totalPages)
                }
                className="next-wrapper-footer font-bold font-20 cursor-pointer"
              >
                Next
                <img
                  className="next-btn-footer rot-180"
                  src="/assets/images/icons/back-arrow.png"
                  alt="next-arrow"
                />
              </div>
            )}
          </div>
        </>
      )}
      <Spinner loading={imagesLoading || loading} />
      <LinkShareModal
        show={showLinkModal}
        onHide={() => setShowLinkModal(false)}
        modalTitle={
          infoType === INFOTYPE.NONE
            ? "Save this link to access your photos in future"
            : infoType === INFOTYPE.AVATAR_NOT_PROCESSED
            ? "User selfie is not processed"
            : infoType === INFOTYPE.NO_MATCHING_PHOTOS
            ? "No matching photos found"
            : "Group has 0 photos"
        }
        url={window.location.href}
        showCopy={
          (allPicsAccess || isResultsFound || group?.isPrivate) &&
          infoType !== INFOTYPE.ZERO_PHOTOS
        }
      >
        {infoType === INFOTYPE.AVATAR_NOT_PROCESSED ? (
          <p className="font-15 text-center font-base">
            Your selfie did not meet our quality standards.
            <br />
            <Link
              to={{
                pathname: "/anonymous/anonymous-selfies",
                search: `?groupId=${groupId}${
                  adminToken
                    ? `&adminToken=${adminToken}`
                    : fGroupCode
                    ? `&fGroupCode=${fGroupCode}`
                    : ""
                }`,
              }}
              replace={true}
              onClick={() => dispatch(clearAnonymousUserData())}
            >
              Click your selfie again
            </Link>
            {(allPicsAccess || group?.isPrivate) && (
              <>
                <br />
                <br />
                Or, visit&nbsp;
                <Link
                  to={modifyAnonymousGallaryRoute(
                    {
                      folderId: "all-photos",
                      pageNo: 1,
                    },
                    params
                  )}
                  onClick={() => {
                    setInfoType(INFOTYPE.NONE);
                    setShowLinkModal(false);
                  }}
                >
                  All Photos
                </Link>
                &nbsp;tab to browse the other photos.
              </>
            )}
          </p>
        ) : infoType === INFOTYPE.NO_MATCHING_PHOTOS ? (
          <p className="font-15 text-center font-base">
            We could not find you in any of the uploaded photos.
            <br />
            Please retry clicking your selfie later.
            {(allPicsAccess || group?.isPrivate) && (
              <>
                <br />
                <br />
                For now, visit&nbsp;
                <Link
                  to={modifyAnonymousGallaryRoute(
                    {
                      folderId: "all-photos",
                      pageNo: 1,
                    },
                    params
                  )}
                  onClick={() => {
                    setInfoType(INFOTYPE.NONE);
                    setShowLinkModal(false);
                  }}
                >
                  All Photos
                </Link>
                &nbsp;tab to browse the other photos.
              </>
            )}
          </p>
        ) : infoType === INFOTYPE.ZERO_PHOTOS ? (
          <p className="font-15 text-center font-base">
            No photos have been uploaded yet.
            <br />
            Please try again later.
          </p>
        ) : null}
      </LinkShareModal>
    </div>
  );
};
export default AnonymouseGalllery;
