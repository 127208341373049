import { takeLatest, put, call, delay } from "redux-saga/effects";
import { AXIOS } from "../../utils/setup/axios";
import {
  GET_MY_GROUPS,
  CREATE_MY_GROUP,
  JOIN_MY_GROUP,
  GET_GROUP_DETAILS,
  CREATE_TRANSFER_REQUEST,
  ACCEPT_REJECT_TRANSFER,
  GET_TRANSFER_DETAILS,
  GET_DELETE_REQUESTS,
  ACCEPT_REJECT_DELETE_REQUEST,
  SEND_NOTIFICATIONS,
  CANCEL_LINK_UPLOAD,
  UPDATE_GROUP_ACCESS,
  DELETE_REARRANGE_SPONSOR,
  BUY_PREMIUM_FEATURE,
  RESET_FULL_ACCESS,
} from "./saga-actions";
import {
  setMyGroups,
  createMyGroup,
  joinMyGroup,
  getGroupDetails,
  setTransferDetails,
  setDeleteRequests,
  updateDeleteRequests,
} from "../slices/group";
import {
  setSaveLoader,
  setSponsors,
  updateParticipant,
} from "redux-store/slices/settings";
import { toast } from "react-toastify";
import { retryAPIDelay, retryErrorMsg } from "./folders";
import { setNotificationResponse } from "redux-store/slices/globals";
import { setOrderDetails } from "redux-store/slices/subscription";
import generateCipher from "utils/helpers/generateCipher";
import ENV from "utils/helpers/env";

async function getMyGroups({ payload }) {
  return AXIOS.get(`/api/app/group/my-groups-v2`, {
    params: {
      ...payload,
      whiteLabelMail: ENV.WL_MAIL,
    },
  });
}

async function createMyGroupApi(action) {
  return AXIOS.post(`api/app/group/create`, action);
}

async function joinMyGroupApi(action) {
  const { groupCode, adminToken } = action;
  if (!adminToken)
    return AXIOS.post(`api/app/group/join`, {
      groupCode,
      whiteLabelMail: ENV.WL_MAIL,
    });

  return AXIOS.post(`api/app/group/join`, {
    groupCode,
    authorization: `Admin-Bearer ${adminToken}`,
    whiteLabelMail: ENV.WL_MAIL,
  });
}

async function getGroupDetailsApi(action) {
  const cipher = generateCipher();
  const { groupCode } = action;

  return AXIOS.get(`api/app/group/details-for-web-join/${groupCode}`, {
    headers: { cipher },
  });
}

async function createTransferRequest(data) {
  const { groupId, receiverId } = data || {};
  return AXIOS.post("/api/app/group/create-transfer-request", {
    groupId,
    receiverId,
  });
}

async function acceptRejectTransfer(data) {
  const { code, requestId, isAccepted } = data || {};
  return AXIOS.post("/api/app/group/accept-reject-transfer-request/", {
    code,
    requestId,
    isAccepted,
  });
}

async function getTransferDetails(data) {
  const cipher = generateCipher();
  const { transferId } = data || {};

  return AXIOS.get("/api/app/group/transfer-details", {
    params: { transferId },
    headers: { cipher },
  });
}

async function getDeleteRequests(data) {
  const { groupId } = data || {};
  return AXIOS.get(`/api/app/pic/delete-requests/${groupId}`);
}

async function acceptRejectDeleteRequest(data) {
  const { requestsIds, isAccept, groupId } = data || {};
  return AXIOS.post("/api/app/pic/action-delete-request", {
    requestsIds,
    isAccept,
    groupId,
  });
}

async function sendNotifications(data) {
  const { groupId, campaignType, users, websiteUrl, mobileUrl } = data || {};

  return AXIOS.post("/api/app/user/send-notification-group-photos-uploaded", {
    groupId,
    campaignType,
    users,
    websiteUrl,
    mobileUrl,
  });
}

async function cancelLinkUpload(data) {
  const { groupId } = data || {};
  return AXIOS.patch(`/api/app/group/stop-cloud-link/${groupId}`);
}

function* getMyGroupsGenerator(action) {
  const page = action?.payload?.page;
  try {
    const response = yield call(getMyGroups, action);
    yield put(setMyGroups({ data: response.data, page }));
  } catch (e) {
    //
  }
}

function* getGroupDetailsGenerator(action) {
  try {
    const response = yield call(getGroupDetailsApi, action);
    yield put(getGroupDetails(response.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
    yield put(getGroupDetails(e?.response));
  }
}

function* createGroupGenerator(obj) {
  var formData = new FormData();
  formData.append("name", obj.formData.name);
  formData.append("icon", obj.formData.icon);
  formData.append("noOfParticipants", obj.formData.noOfParticipants);
  formData.append("participants", obj.formData.participants);
  formData.append("isPrivate", obj.formData.isPrivate);
  formData.append("canAnyoneUploadPhotos", obj.formData.canAnyoneUploadPhotos);
  formData.append(
    "newJoineShowPreviousPhotos",
    obj.formData.newJoineShowPreviousPhotos
  );
  formData.append("anyOneJoinWithLink", obj.formData.anyOneJoinWithLink);
  formData.append("isForProductSale", obj.formData.isForProductSale);

  try {
    const response = yield call(createMyGroupApi, formData);
    toast.success(response?.data?.message);

    yield put(createMyGroup(response.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  }
}

function* joinGroupGenerator(data) {
  try {
    const response = yield call(joinMyGroupApi, data);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(joinMyGroup(response.data));
  } catch (error) {
    if (
      error?.response?.data?.message === "You are already group participant" ||
      error?.response?.data?.message === "Invalid group code" ||
      error?.response?.data?.message ===
        "Full view access granted successfully." ||
      error?.response?.data?.message ===
        "Joining is not allowed in this group, please contact group admin"
    ) {
      toast.error(error?.response?.data?.message);
      yield put(joinMyGroup(error?.response?.data));
      return;
    }
    try {
      yield delay(retryAPIDelay);
      const response = yield call(joinMyGroupApi, data);
      if (response?.data?.status === 200 || response?.data?.status === 201) {
        toast.success(response?.data?.message);
      }
      yield put(joinMyGroup(response.data));
    } catch (e) {
      if (e.response.status === 400 || e.response.status === 403) {
        toast.error(retryErrorMsg);
      }
      yield put(joinMyGroup(e?.response?.data));
      toast.error(e?.response?.data?.message);
    }
  }
}

function* createTransferRequestGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(createTransferRequest, action?.payload);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* acceptRejectTransferGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(acceptRejectTransfer, action?.payload);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* getTransferDetailsGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(getTransferDetails, action?.payload);
    if (response?.data?.status === 200) {
      yield put(setTransferDetails(response?.data));
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
    window.location.href = "/not-found";
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* getDeleteRequestsGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(getDeleteRequests, action?.payload);
    yield put(setDeleteRequests(response?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* postAcceptRejectGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(acceptRejectDeleteRequest, action?.payload);
    yield put(updateDeleteRequests(response?.data));
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* sendNotificationsGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(sendNotifications, action?.payload);
    yield put(setNotificationResponse(response?.data));
  } catch (e) {
    if (e.response?.data?.message.includes("credits more"))
      yield put(setNotificationResponse(e?.response?.data));
    else toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* cancelLinkUploadGenerator(action) {
  yield put(setSaveLoader(true));
  try {
    const response = yield call(cancelLinkUpload, action?.payload);
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function updateGroupAccess(data) {
  const { id, participantId, allPicsAccess } = data || {};
  return AXIOS.patch(`/api/app/group/updateGroupAccess/${id}`, {
    participantId,
    allPicsAccess,
  });
}

function* updateGroupAccessGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(updateGroupAccess, action?.payload);
    yield put(updateParticipant(response?.data?.data));
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function deleteRearrangeSponsor(data) {
  return AXIOS.post("/api/app/group/delete-rearrange-sponsor", data);
}

function* deleteRearrangeSponsorGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(deleteRearrangeSponsor, action?.payload);
    yield put(setSponsors(response.data));
    toast.success(response.data?.message);
  } catch (e) {
    toast.error(e?.response.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

function upgradeGroupPremiumFeature(data) {
  return AXIOS.post("/api/app/group/upgrade-group-premium-feature", data);
}

function* upgradeGroupPremiumFeatureGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(upgradeGroupPremiumFeature, action?.payload);
    yield put(setOrderDetails(response?.data?.data?.orderData || {}));
  } catch (e) {
    toast.error(e?.response.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function resetFullAccess({ groupId }) {
  return AXIOS.patch("/api/app/group/reset-full-access", {
    groupId,
  });
}

function* resetFullAccessGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(resetFullAccess, action?.payload);
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

export function* groupsSaga() {
  yield takeLatest(GET_MY_GROUPS, getMyGroupsGenerator);
  yield takeLatest(CREATE_MY_GROUP, createGroupGenerator);
  yield takeLatest(JOIN_MY_GROUP, joinGroupGenerator);
  yield takeLatest(GET_GROUP_DETAILS, getGroupDetailsGenerator);
  yield takeLatest(CREATE_TRANSFER_REQUEST, createTransferRequestGenerator);
  yield takeLatest(ACCEPT_REJECT_TRANSFER, acceptRejectTransferGenerator);
  yield takeLatest(GET_TRANSFER_DETAILS, getTransferDetailsGenerator);
  yield takeLatest(GET_DELETE_REQUESTS, getDeleteRequestsGenerator);
  yield takeLatest(ACCEPT_REJECT_DELETE_REQUEST, postAcceptRejectGenerator);
  yield takeLatest(SEND_NOTIFICATIONS, sendNotificationsGenerator);
  yield takeLatest(CANCEL_LINK_UPLOAD, cancelLinkUploadGenerator);
  yield takeLatest(UPDATE_GROUP_ACCESS, updateGroupAccessGenerator);
  yield takeLatest(DELETE_REARRANGE_SPONSOR, deleteRearrangeSponsorGenerator);
  yield takeLatest(BUY_PREMIUM_FEATURE, upgradeGroupPremiumFeatureGenerator);
  yield takeLatest(RESET_FULL_ACCESS, resetFullAccessGenerator);
}
