import React, { useEffect, useRef, useState } from "react";
import SettingPageHeading from "./components/SettingPageHeading";
import { useSelector, useDispatch } from "react-redux";
import { randomID } from "../components/radio/SelectRadioGroup";
import Spinner from "../components/loader/Spinner";
import { useSearchParams } from "react-router-dom";
import {
  // GET_GROUP_DESIGNA_AND_SETTINGS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../redux-store/sagas/saga-actions";
// import { GET_GROUP_DETAILS_AND_OTHER_SETTINGS } from "../../redux-store/sagas/saga-actions";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import { deepEqual } from "utils/helpers";
import "./css/GeneralSettings.css";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import SubscriptionModal from "views/subscription";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { PAID_FEATURES } from "constants/plans";
import ANALYTICS from "constants/analyticsKeys";

function generateDesignData(designSetting, design) {
  let result = [];
  if (Object.keys(designSetting).length === 0) return result;

  result = [
    {
      id: "0",
      title: "Colour Mode",
      subTitle:
        "Choose between the two colour modes for the background of the gallery",
      selectedValue: design?.colorMode || designSetting.colorMode,
      options: [
        {
          label: "Light",
          icon: "/assets/images/icons/light.png",
          value: 1,
          key: "colorMode",
        },
        {
          label: "Dark",
          icon: "/assets/images/icons/dark.png",
          value: 2,
          key: "colorMode",
        },
      ],
    },
    {
      id: "1",
      title: "Photo Size",
      subTitle: "Choose between the two sizes of photo display",
      selectedValue: design?.photoSize || designSetting.photoSize,
      options: [
        {
          label: "Large ",
          icon: "/assets/images/icons/size1.png",
          value: 1,
          key: "photoSize",
        },
        {
          label: "Small",
          icon: "/assets/images/icons/size2.png",
          value: 2,
          key: "photoSize",
        },
      ],
    },
    {
      id: "2",
      title: "Font",
      subTitle:
        "Choose between the two kinds of typography to compliment your gallery",
      selectedValue: design?.font || designSetting.font,

      options: [
        {
          label: "Serif",
          icon: "/assets/images/icons/font2.png",
          value: 1,
          key: "font",
        },
        {
          label: "Sans",
          icon: "/assets/images/icons/font1.png",
          value: 2,
          key: "font",
        },
      ],
    },
    {
      id: "3",
      title: "Grid Structure",
      subTitle: "Choose between the two kinds of grid to form your gallery",
      selectedValue: design?.grid || designSetting.grid,

      options: [
        {
          label: "Horizontal",
          icon: "/assets/images/icons/horizontal.png",
          value: 1,
          key: "grid",
        },
        {
          label: "Vertical",
          icon: "/assets/images/icons/vertical.png",
          value: 2,
          key: "grid",
        },
      ],
    },
    {
      id: "4",
      title: "Padding",
      subTitle: "Choose between the two padding spaces between photos",
      selectedValue: design?.padding || designSetting.padding,
      options: [
        {
          label: "Large",
          key: "padding",
          icon: "/assets/images/icons/padding2.png",
          value: 1,
        },
        {
          label: "Small",
          key: "padding",
          icon: "/assets/images/icons/padding1.png",
          value: 2,
        },
      ],
    },
  ];

  return result;
}

const DesignSettings = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const [subscriptionModal, setSubscriptionModal] = useState();
  const { hasAccessToFeature } = useSubscription();

  const {
    designSettings,
    saveLoader,
    groupId,
    settingLoader,
    groupDetails: { createdBy },
  } = useSelector((state) => state.settings);

  const { _id: currentUserId } = getLocalStorageUser();

  const initialValues = useRef({});
  const [design, setDesign] = useState({});
  const [mode, setMode] = useState(null);
  const [isCover, setIsCover] = useState(true);
  const [coverSetting, setCoverSetting] = useState(null);

  usePreventUnsavedNavigation({
    message: "You have unsaved changes. Are you sure you want to leave?",
    block: !deepEqual(initialValues.current, design),
  });

  useEffect(() => {
    if (Object.keys(designSettings).length > 0) {
      setDesign(designSettings);
      initialValues.current = designSettings;
      const settingPara = initialValues.current;
      const settingValues = `${settingPara.colorMode}${settingPara.photoSize}${settingPara.font}${settingPara.grid}${settingPara.padding}`;
      const modeData = `${settingPara.colorMode}${settingPara.font}${settingPara.template}`;
      setCoverSetting(modeData);
      setMode(settingValues);
    }
  }, [designSettings]);

  const radioGrouplist = generateDesignData(designSettings, design);

  const handeSave = () => {
    // this is to check whether user has the access to change design settings

    if (
      currentUserId === createdBy &&
      !(
        hasAccessToFeature(PLAN_FEATURES.group_gallery_design_templates) ||
        designSettings?.designAccess
      )
    ) {
      setSubscriptionModal(true);
      return;
    }

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.DESIGN_SAVE] },
    });
    dispatch({
      type: "POST_GROUP_DESIGN_AND_SETTING",
      payload: { design, groupId, source: "design" },
    });
    initialValues.current = design;
  };

  const changeSetting = (value) => {
    if (value.template) {
      setIsCover(true);
      const settingPara = { ...design };
      const modeData = `${settingPara.colorMode}${settingPara.font}${value.template}`;
      setCoverSetting(modeData);
      setDesign({ ...design, template: value.template });
    } else {
      setIsCover(false);
      setDesign({ ...design, [value.key]: value.value });
      const settingPara = { ...design, [value.key]: value.value };
      const modeData = `${settingPara.colorMode}${settingPara.photoSize}${settingPara.font}${settingPara.grid}${settingPara.padding}`;
      setMode(modeData);
    }
  };

  const clickCover = () => {
    setIsCover(true);
    const settingPara = { ...design };
    const modeData = `${settingPara.colorMode}${settingPara.font}${settingPara.template}`;
    setCoverSetting(modeData);
  };

  const clickGallery = () => {
    setIsCover(false);
    const settingPara = { ...design };
    const modeData = `${settingPara.colorMode}${settingPara.photoSize}${settingPara.font}${settingPara.grid}${settingPara.padding}`;
    setMode(modeData);
  };

  const SelectRadioTemplateList = [
    {
      id: "template-1",
      template: `/assets/images/cover-tinyjpg/${design?.colorMode}21.png`,
      label: "Original",
    },
    {
      id: "template-2",
      template: `/assets/images/cover-tinyjpg/${design?.colorMode}22.png`,
      label: "Bordered",
    },
    {
      id: "template-3",
      template: `/assets/images/cover-tinyjpg/${design?.colorMode}23.png`,
      label: "Left Aligned",
    },
    {
      id: "template-4",
      template: `/assets/images/cover-tinyjpg/${design?.colorMode}24.png`,
      label: "Label",
    },
    {
      id: "template-5",
      template: `/assets/images/cover-tinyjpg/${design?.colorMode}25.png`,
      label: "Central Card",
    },
  ];

  return (
    <div>
      <Spinner loading={saveLoader || settingLoader} />
      {subscriptionModal && (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
          groupId={groupId}
          src={PAID_FEATURES.group_gallery_design_templates}
        />
      )}
      <SettingPageHeading
        title="Design Setting"
        btnTitle="Save"
        onClick={handeSave}
        goBack={token && !noback ? () => history.back() : false}
      />
      <div className="row switchOnMobile">
        <div className="col-md-12 col-lg-12 col-xl-5">
          {radioGrouplist.map((item) => {
            return (
              <SelectRadioGroup
                key={item.title + item.id}
                onChange={(value) => changeSetting(value)}
                title={item.title}
                subTitle={item.subTitle}
                selectedValue={item.selectedValue}
                options={item.options}
              />
            );
          })}
        </div>

        <div className="col-md-12 col-lg-12 col-xl-7">
          <div
            className="mt-4 d-flex flex-column align-items-center"
            style={{ position: "sticky", top: "0" }}
          >
            <h3 className="font-15">Template</h3>
            <p className="dark-gray-text thick-font">
              Choose between different templates to suit your design{" "}
            </p>
            <div className="mainTemplate thick-font">
              <div className="d-flex justify-content-between align-items-center gray-text">
                <ul className="d-flex align-items-center ps-0 mb-0">
                  <li
                    className={
                      isCover
                        ? "active me-3 cursor-pointer"
                        : "me-3 cursor-pointer gray-text"
                    }
                    onClick={clickCover}
                  >
                    Cover
                  </li>
                  <li
                    className={
                      !isCover
                        ? "active me-3 cursor-pointer"
                        : "me-3 cursor-pointer gray-text"
                    }
                    onClick={clickGallery}
                  >
                    Gallery
                  </li>
                </ul>
              </div>
              {isCover ? (
                <img
                  src={`/assets/images/cover-tinyjpg/${coverSetting}.png`}
                  alt="main thumbnail"
                />
              ) : (
                <img
                  src={`/assets/images/gallery-tinyjpg/${mode}.png`}
                  alt="main thumbnail"
                />
              )}
            </div>

            <div className="settingRadioCardWrapper">
              <SelectRadioTemplate
                title="selectTemplate"
                SelectRadioTemplateList={SelectRadioTemplateList}
                selectedTemplate={design?.template || designSettings.template}
                onChange={(value) => changeSetting({ template: value })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Need to make this a reusable component
 */
const SelectRadioGroup = ({
  subTitle,
  title,
  options,
  selectedValue,
  onChange,
}) => {
  const [select, setSelect] = useState(selectedValue);

  useEffect(() => {
    setSelect(selectedValue);
  }, [selectedValue]);

  const handleChange = ({ target }) => {
    const selectedID = target.id;
    const selectedIDIndex = selectedID.split("-")[1];
    onChange(options[selectedIDIndex - 1]);
  };

  return (
    <div className="settingRadioCard designSetting mt-4">
      <h3 className="mb-0 me-2 font-15 bright-blue-text">{title}</h3>
      <p>{subTitle ? subTitle : ""}</p>
      <div className="d-flex">
        {options.map((option) => {
          const _id = randomID();

          return (
            <div className="form-check me-3" key={_id}>
              <input
                className="form-check-input d-none"
                type="radio"
                id={_id + "-" + option.value}
                name={title}
                checked={select === option.value}
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                htmlFor={_id + "-" + option.value}
              >
                <div className="selectIcon">
                  <img src={option.icon} alt="icon" />
                </div>
                <h4>{option.label}</h4>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SelectRadioTemplate = ({
  SelectRadioTemplateList,
  title,
  selectedTemplate,
  onChange,
}) => {
  return (
    <div className="settingRadioCard templateSetting mt-2">
      <div className="d-flex justify-content-center flex-wrap">
        {SelectRadioTemplateList.map((item) => {
          return (
            <div key={item.id} className="form-check thick-font gray-text">
              <input
                className="form-check-input d-none"
                type="radio"
                name={title}
                id={item.id}
                checked={"template-" + selectedTemplate === item.id}
                onChange={() => {
                  onChange(Number(item.id.split("-")[1]));
                }}
              />
              <label className="form-check-label" htmlFor={item.id}>
                <img src={item.template} alt="templete1" />
                {/* <h4>{select1}</h4> */}
              </label>
              <span>{item.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DesignSettings;
