import React, { useEffect, useRef, useState } from "react";
import SettingPageHeading from "../components/SettingPageHeading";
import DefaultInput from "../../components/input/DefaultInput";
import SecondaryButton from "../../components/button/SmallRoundedButton";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/loader/Spinner";
import { setSaveLoader } from "../../../redux-store/slices/settings";
import {
  SEND_USER_CLICK_ANALYTICS,
  PUT_GROUP_GENERAL_SETTINGS,
} from "../../../redux-store/sagas/saga-actions";
import { useSearchParams } from "react-router-dom";
import { Col, Dropdown, Modal, Row, Container } from "react-bootstrap";
import FocusPicker from "../components/focus-picker";
import FocusedImage from "../components/focused-image";
import "../css/GeneralSettings.css";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import MutedButton from "views/components/button/MutedButton";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import ImageUploaderWithPreview from "views/components/input/ImageUploaderWithPreview";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import css from "./index.module.css";
import ANALYTICS from "constants/analyticsKeys";

const UserAgentErrorModal = React.lazy(() =>
  import("views/components/modals/UserAgentModal")
);

export const SORT_STRATEGIES = {
  UPLOADED_AT_DESC: {
    label: "Upload Time - Newest to Oldest",
    image: "/assets/images/icons/sorting/9to1",
  },
  UPLOADED_AT_ASC: {
    label: "Upload Time - Oldest to Newest",
    image: "/assets/images/icons/sorting/1to9",
  },
  NAME_ASC: {
    label: "Name - A to Z",
    image: "/assets/images/icons/sorting/atoz",
  },
  NAME_DESC: {
    label: "Name - Z to A",
    image: "/assets/images/icons/sorting/ztoa",
  },
  CAPTURED_AT_DESC: {
    label: "Captured Time - Newest to Oldest",
    image: "/assets/images/icons/sorting/9to1",
  },
  CAPTURED_AT_ASC: {
    label: "Captured Time - Oldest to Newest",
    image: "/assets/images/icons/sorting/1to9",
  },
};

const MODAL_TYPES = {
  USERAGENT_ERROR: "userAgentError",
  MOBILE_COVER: "mobileCover",
  DELETE_COVER: "deleteCover",
  NONE: "",
};

const MOBILE_COVER = {
  MAX_HEIGHT: 1100,
  MAX_WIDTH: 600,
};

const resizeFile = (file, quality) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1080,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      576,
      324
    );
  });

const GeneralSettings = () => {
  const coverInputRef = useRef(null);
  const dispatch = useDispatch();
  const imageChangedRef = useRef(false);

  const {
    generalSettings,
    saveLoader,
    settingLoader,
    leaveParticipantSettings,
  } = useSelector((state) => state.settings);

  // const [params] = useSearchParams();

  // useEffect(() => {
  //   dispatch(setSettingLoader(true));
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: params.get("groupId"),
  //   });
  // }, []);

  const [settings, setSettings] = React.useState(generalSettings);
  const { groupName } = settings;
  const [lgShow, setLgShow] = useState(false);
  const [focus, setFocus] = useState({
    x: 0.0,
    y: 0.0,
  });
  const [coverImageResult, setCoverImageResult] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const mobileCoverRef = useRef(null);
  const [mobileCoverBase64, setMobileCoverBase64] = useState("");
  const [hideCover, setHideCover] = useState(false);
  const [modal, setModal] = useState(MODAL_TYPES.NONE);

  const openModal = () => {
    setLgShow(true);
  };

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  useEffect(() => {
    if (generalSettings?.iconFocalPoint) {
      setFocus({
        x: +generalSettings?.iconFocalPoint.split("/")[0],
        y: +generalSettings?.iconFocalPoint.split("/")[1],
      });
    }
    setSettings(generalSettings);
  }, [coverImageResult, generalSettings]);

  /**
   * @param {Event & { target:HTMLInputElement }} e
   */
  const handleOnChange = async (e) => {
    if (e?.target?.files?.length > 0) {
      dispatch(setSaveLoader(true));
      const file = e.target.files[0];

      const image = new Image();
      image.src = URL.createObjectURL(file);

      const isOk = await new Promise((resolve) => {
        image.onload = () => {
          if (image.width > 12000 || image.height > 12000) {
            toast.error("Image dimension should not exceed 12000x12000");
            resolve(false);
          }
          resolve(true);
        };
        image.onerror = () => {
          toast.error("Image can't be read, please select another.");
          resolve(false);
        };
      });

      if (!isOk) return dispatch(setSaveLoader(false));

      const img = await resizeFile(file, 50);
      setCoverImageResult(img);
      imageChangedRef.current = true;
      setImageRef(file);
      dispatch(setSaveLoader(false));
      setLgShow(true);
    }
    if ("value" in coverInputRef.current) {
      coverInputRef.current.value = null;
    }
  };

  const handleFileUpload = (
    fileupload = false,
    key = null,
    forMobile = false
  ) => {
    let payloadData = null;
    if (fileupload) {
      payloadData = {
        ...settings,
        iconFocalPoint: `${focus.x}/${focus.y}`,
        imageChanged: imageChangedRef.current,
        groupCover: forMobile ? mobileCoverRef.current.files[0] : imageRef,
        forMobile,
        ...(key && { sortStrategy: key }),
      };
    } else {
      payloadData = {
        ...settings,
        imageChanged: false,
        groupCover: null,
        ...(key && { sortStrategy: key }),
      };
    }

    setLgShow(false);
    dispatch(setSaveLoader(true));
    dispatch({
      type: PUT_GROUP_GENERAL_SETTINGS,
      payload: payloadData,
    });
    imageChangedRef.current = false;
    setHideCover(false);
  };

  const deleteImage = () => {
    if (!leaveParticipantSettings?.isAdmin)
      return toast.error("Only admin can delete the cover image.");

    setModal(MODAL_TYPES.DELETE_COVER);
  };

  const handleDelete = () => {
    const payloadData = { groupId: settings["groupID"] };
    dispatch({
      type: "DELETE_GROUP_ICON",
      payload: payloadData,
    });

    setModal(MODAL_TYPES.NONE);
    setCoverImageResult(null);
    setHideCover(true);
  };

  const handleUploadCover = () => {
    if (token) {
      setModal(MODAL_TYPES.USERAGENT_ERROR);
    } else {
      setImageRef(null);
      setHideCover(true);
    }
  };

  const handleBrowse = (e) => {
    if (token) {
      e.preventDefault();
      setModal(MODAL_TYPES.USERAGENT_ERROR);
    } else {
      coverInputRef.current.click();
    }
  };

  const handleUploadMobileCoverDropdown = () => {
    if (token) {
      setModal(MODAL_TYPES.USERAGENT_ERROR);
    } else {
      setModal(MODAL_TYPES.MOBILE_COVER);
    }
  };

  /**
   * @param {Event & { target:HTMLInputElement }} e
   */
  const getMobileCoverBase64 = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > 5 * 1024 * 1024)
        return toast.error("File size should not exceed 5MB");

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        if (
          image.width > MOBILE_COVER.MAX_WIDTH ||
          image.height > MOBILE_COVER.MAX_HEIGHT
        )
          return toast.error(
            `Image dimensions should not exceed ${MOBILE_COVER.MAX_WIDTH}x${MOBILE_COVER.MAX_HEIGHT}`
          );

        const reader = new FileReader();
        reader.onload = (e) => {
          imageChangedRef.current = true;
          setMobileCoverBase64(e.target.result);
        };
        reader.onerror = () => {
          toast.error("Image can't be read, please select another.");
        };
        reader.readAsDataURL(file);
      };

      image.onerror = () => {
        toast.error("Image can't be read, please select another.");
      };
    }
  };

  const handleMobileCoverUpload = () => {
    if (!imageChangedRef.current)
      return toast.error("Please select an image to upload");

    handleFileUpload(true, null, true);
    setModal(MODAL_TYPES.NONE);

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.COVER_BROWSE_SAVE] },
    });
  };

  return (
    <Container fluid className="g-0">
      <Row>
        <Col>
          <SettingPageHeading
            title="General Setting"
            goBack={token && !noback ? () => history.back() : false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className="mt-4 group-name-div">
          <h4>Group Name</h4>
          <div className="d-flex">
            <DefaultInput
              value={groupName || ""}
              onChange={(e) =>
                setSettings({ ...settings, groupName: e.target.value })
              }
              placeholder="John & Joe Wedding"
            />
            <div className="ms-2" />
            <SecondaryButton
              title="Save"
              rounded={false}
              variant="md"
              className="px-4"
              onClick={() => {
                handleFileUpload(false);
              }}
            />
          </div>

          {!hideCover && generalSettings?.coverIcon ? (
            <>
              <h4 className="focus-header my-4">Cover Image / Group Icon</h4>
              <div className="focus-image-container">
                <div className="focus-image-container-desktop-wrapper">
                  <div className="focus-image-container-desktop">
                    <FocusedImage
                      imageSrc={
                        coverImageResult ||
                        generalSettings?.icon ||
                        generalSettings?.coverIcon
                      }
                      x={focus?.x}
                      y={focus?.y}
                    />
                  </div>
                  <div className="group-info">
                    <p>{groupName}</p>
                    <div className="cover-image-controls">
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/setting.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/share.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/download.png" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="focus-image-container-mobile-wrapper">
                  <div className="focus-image-container-mobile">
                    {/* <FocusedImage
                      imageSrc={
                        coverImageResult ||
                        generalSettings?.icon ||
                        generalSettings?.coverIcon
                      }
                      x={focus?.x}
                      y={focus?.y}
                    /> */}
                    <img
                      src={
                        mobileCoverBase64 ||
                        (generalSettings?.icon || "").replace(
                          "small-icons",
                          "mobile-cover-icons"
                        ) ||
                        (generalSettings?.coverIcon || "").replace(
                          "cover-icons",
                          "mobile-cover-icons"
                        ) ||
                        ""
                      }
                      alt="mobile preview"
                      className="w-100 h-100 object-fit-cover"
                      onError={(e) => {
                        e.target.src =
                          generalSettings?.icon || generalSettings?.coverIcon;
                      }}
                    />
                  </div>
                  <div className="group-info">
                    <p>{groupName}</p>
                    <div className="cover-image-controls">
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/setting.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/share.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/download.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="conver-edit-dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    className="cover-edit"
                    variant="primary"
                    id="dropdown-basic"
                  >
                    Edit
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="p-0">
                    <Dropdown.Item onClick={openModal}>
                      <img src="/assets/images/landing/Group685.svg" alt="" />
                      Focal Point
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleUploadCover}>
                      <img src="/assets/images/landing/upload.svg" alt="" />
                      Upload Photo
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleUploadMobileCoverDropdown}>
                      <img
                        src="/assets/images/landing/upload.svg"
                        alt="upload mobile cover"
                      />
                      Upload Mobile Cover
                    </Dropdown.Item>
                    <Dropdown.Item onClick={deleteImage}>
                      <img
                        src="/assets/images/landing/Vector.svg"
                        alt="delete"
                      />
                      Delete Photo
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          ) : (
            <div>
              <div className="mt-4">
                <h4>Cover Image / Group Icon</h4>
                <label className="dropableCard mx-0 mt-3" htmlFor="file">
                  <input
                    ref={coverInputRef}
                    type="file"
                    id="file"
                    accept=".png, .jpg, .jpeg"
                    className="d-none"
                    name="coverImage"
                    onClick={handleBrowse}
                    onChange={handleOnChange}
                  />
                  <p className="font-14 mb-0">Upload Cover Image</p>
                  <div className="mt-4" />
                  <SecondaryButton title="Browse" onClick={handleBrowse} />
                  <div className="mt-4" />
                  <p className="mb-0">Recommended size - 2160 x 1080 pixels</p>
                  <p className="mb-0">Formats supported are JPEG & PNG</p>
                </label>
              </div>
            </div>
          )}
        </Col>

        {leaveParticipantSettings?.isAdmin && (
          <Col xs={12} lg={6} className={css["sort-div"]}>
            <h4>Sort Photos</h4>
            <Dropdown onSelect={(key) => handleFileUpload(false, key)}>
              <Dropdown.Toggle className="sort-dropdown">
                <div
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.SORT_PHOTOS] },
                    })
                  }
                >
                  {SORT_STRATEGIES[generalSettings?.sortStrategy] ? (
                    <>
                      <img
                        src={
                          SORT_STRATEGIES[generalSettings.sortStrategy].image +
                          "-active.png"
                        }
                      />
                      {SORT_STRATEGIES[generalSettings.sortStrategy].label}
                    </>
                  ) : (
                    "Select sort strategy"
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0">
                {Object.entries(SORT_STRATEGIES).map((strategy) => (
                  <Dropdown.Item
                    key={strategy[0]}
                    eventKey={strategy[0]}
                    active={strategy[0] === generalSettings?.sortStrategy}
                  >
                    <img
                      src={`${strategy[1].image}${
                        strategy[0] === generalSettings?.sortStrategy
                          ? "-active.png"
                          : ".png"
                      }`}
                    />
                    {strategy[1].label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>

      <Modal
        show={lgShow}
        dialogClassName="modal-80w"
        onHide={() => {
          setLgShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="custom-header">
          <h2 className="focal-point-title">Set Focal Point</h2>
        </div>
        <Modal.Body className="preview-wrapper">
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className="focus-image-container">
                <FocusPicker
                  imageSrc={coverImageResult || settings?.coverIcon}
                  onFocusChanged={setFocus}
                  startingFocus={focus}
                />
              </div>
            </Col>
            <Col md={6} lg={6} xs={12}>
              <div className="dropdown-divider mt-3 d-md-none"></div>
              <h1 className="focus-header text-center mt-2 mt-md-0">Preview</h1>
              <div className="focus-image-container">
                <div className="focus-image-container-desktop-wrapper">
                  <div className="focus-image-container-desktop">
                    <FocusedImage
                      imageSrc={coverImageResult || settings?.coverIcon}
                      x={focus?.x}
                      y={focus?.y}
                    />
                  </div>
                  <div className="group-info">
                    <p>{groupName}</p>
                    <div className="cover-image-controls">
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/setting.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/share.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/download.png" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="focus-image-container-mobile-wrapper">
                  <div className="focus-image-container-mobile">
                    <FocusedImage
                      imageSrc={coverImageResult || settings?.coverIcon}
                      x={focus?.x}
                      y={focus?.y}
                    />
                  </div>
                  <div className="group-info">
                    <p>{groupName}</p>
                    <div className="cover-image-controls">
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/setting.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/share.png" />
                      </div>
                      <div className="icon-wrapper">
                        <img src="/assets/images/icons/download.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            title="Save"
            onClick={() => {
              handleFileUpload(true);

              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.COVER_BROWSE_SAVE] },
              });
            }}
          />
        </Modal.Footer>
      </Modal>

      {/* delete model */}
      {modal === MODAL_TYPES.DELETE_COVER && (
        <Modal
          show={true}
          dialogClassName="modal-60w"
          onHide={() => {
            setModal(MODAL_TYPES.NONE);
          }}
          centered
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="custom-header p-4">
            <h2 className="focal-point-header">
              Are you sure you want to delete group cover image?
            </h2>
          </div>
          <Modal.Body className="preview-wrapper gap-3 d-flex justify-content-center align-items-center">
            <MutedButton
              onClick={() => {
                setModal(MODAL_TYPES.NONE);
              }}
              title="Cancel"
            />
            <SecondaryButton onClick={handleDelete} title="Yes, remove" />
          </Modal.Body>
        </Modal>
      )}

      <React.Suspense fallback={<SuspenseLoader />}>
        {modal === MODAL_TYPES.MOBILE_COVER && (
          <ConfirmationModal
            show={true}
            onCancel={() => {
              setModal(MODAL_TYPES.NONE);
            }}
            confirmText="Upload"
            onConfirm={handleMobileCoverUpload}
            animation
            buttonVariant="xs"
            buttonRounded={false}
          >
            <ImageUploaderWithPreview
              inputRef={mobileCoverRef}
              title="Upload Mobile Cover"
              titleClassName="font-bold color-secondary"
              onChange={getMobileCoverBase64}
              previewImage={mobileCoverBase64}
              uploadCardOverride={css["mobile-cover-upload-card"]}
              requirements={
                <>
                  <span className="font-13 font-bold">
                    Recommended Size - 540 x 1080 pixels
                  </span>
                  <br />
                  Max Width - 600 pixels
                  <br />
                  Max Height - 1100 pixels
                  <br />
                  Max File size - 5 MB
                  <br />
                  JPEG & PNG only
                </>
              }
            />
          </ConfirmationModal>
        )}

        {modal === MODAL_TYPES.USERAGENT_ERROR && (
          <UserAgentErrorModal
            show={true}
            onHide={() => setModal(MODAL_TYPES.NONE)}
            variant={1}
          />
        )}
      </React.Suspense>

      <Spinner loading={saveLoader || settingLoader} />
    </Container>
  );
};

export default GeneralSettings;
