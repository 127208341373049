import React from "react";
import { useSelector } from "react-redux";

/**
 * Component to render branding icons for photographer
 * @typedef {object} MediaIconsProps
 * @property {boolean} isEditing - Flag to check if user is making edits, used for live preview
 * @property {object} businessData - Business data object
 * @property {boolean} isAnonymous - If user is anonymous
 * @property {boolean} isLoginPage - If user is on auth pages
 * @param {MediaIconsProps} props
 */
const MediaIcons = ({
  isEditing,
  businessData,
  isAnonymous = false,
  isLoginPage = false,
}) => {
  let { brandingData } = useSelector((state) => state.settings);
  const { anonymousUser } = useSelector((state) => state.anonymous);
  const groupDetails = useSelector((state) => state.groups.group?.data);

  if (isEditing) {
    brandingData = businessData;
  } else if (isAnonymous) {
    brandingData = anonymousUser?.brandingData;
  } else if (isLoginPage) {
    brandingData = groupDetails?.brandingData;
  }

  const {
    insta_link,
    insta_link_d,
    fb_link,
    fb_link_d,
    website,
    website_d,
    business_phone,
    business_phone_d,
    business_email,
    business_email_d,
  } = brandingData || {};

  return (
    <div>
      <ul className="ps-0 mb-0 d-flex">
        {insta_link_d && insta_link && (
          <li className="no-margin">
            <a
              id="hw-25px"
              className="d-inline-block"
              href={insta_link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hw-18px"
                src="../../../assets/images/icons/business_instagram.png"
                alt="icon"
              />
            </a>
          </li>
        )}
        {fb_link_d && fb_link && (
          <li className="no-margin">
            <a
              id="hw-25px"
              className="d-inline-block"
              href={fb_link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hw-18px"
                src="../../../assets/images/icons/business_facebook.png"
                alt="icon"
              />
            </a>
          </li>
        )}
        {website_d && website && (
          <li className="no-margin">
            <a
              id="hw-25px"
              className="d-inline-block"
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hw-18px"
                src="../../../assets/images/icons/business_click.png"
                alt="icon"
              />
            </a>
          </li>
        )}
        {business_phone && business_phone_d && (
          <li className="no-margin">
            <a
              id="hw-25px"
              className="d-inline-block"
              href={`tel:${business_phone}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hw-18px"
                src="../../../assets/images/icons/business_phone.png"
                alt="icon"
              />
            </a>
          </li>
        )}
        {business_email && business_email_d && (
          <li className="no-margin">
            <a
              id="hw-25px"
              className="d-inline-block"
              href={`mailto:${business_email}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hw-18px"
                src="../../../assets/images/icons/business_email.png"
                alt="icon"
              />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
export default MediaIcons;
