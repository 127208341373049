import SettingPageHeading from "./components/SettingPageHeading";
import ParticipantsTable from "../components/table/ParticipantsTable";
import { toast } from "react-toastify";
import React, { useEffect } from "react";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_BRANCH_URL,
  GET_BRANCH_WEB_URL,
  PATCH_LEAVE_GROUP,
  SEND_NOTIFICATIONS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../redux-store/sagas/saga-actions";
import Spinner from "../components/loader/Spinner";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setLeaveGroupStatus } from "../../redux-store/slices/settings";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import useUploadStatus from "hooks/useUploadingStatus";
import IconedButton2 from "views/components/button/IconedButton2";
import classNames from "classnames";
import css from "./css/Participants.module.css";
import { Card } from "react-bootstrap";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { clearNotificationResponse } from "redux-store/slices/globals";
import { shouldShowTransferInfo } from "utils/helpers/localstorage";
import Input from "views/components/input/Input";
import { debounce } from "lodash";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import ANALYTICS from "constants/analyticsKeys";

const SelectUserList = React.lazy(() =>
  import("views/components/modals/SelectUserList")
);

const NOTIFICATION_MODES = {
  SMS: 1,
  WHATSAPP: 2,
  EMAIL: 3,
};

const MODAL_TYPES = {
  USER_LIST: "user_list",
  NOTIFICATION_MODE: "notification_mode",
  CONFIRMATION: "confirmation",
  NONE: "none",
};

const MODAL_INIT_STATE = {
  show: false,
  type: "leave",
  title: "",
  showTitle: true,
  message: "",
  cancelButton: null,
  cancelButtonText: "",
  confirmButton: null,
  confirmButtonText: "",
};

const Participants = () => {
  const [modal, setModal] = React.useState(MODAL_INIT_STATE);
  const [loading, setLoading] = React.useState(false);
  const { isParallelyUploading, uploadingFromLink } = useUploadStatus();
  const { state } = useLocation();
  const highlight = state?.highlight;

  const [searchText, setSearchText] = React.useState("");
  const [notificationsMode, setNotificationsMode] = React.useState(
    NOTIFICATION_MODES.WHATSAPP
  );

  const [notificationModal, setNotificationModal] = React.useState(
    MODAL_TYPES.NONE
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    leaveParticipantSettings,
    leaveGroupStatus: { status, message },
    participantsSettings: { participants = [] },
    groupDetails: {
      createdBy,
      isPrivate,
      isAdmin,
      name,
      groupCode,
      icon,
      branchURL,
      noOfParticipants,
    },
    saveLoader,
  } = useSelector((state) => state.settings);

  const {
    notificationResponse: { status: notiStatus },
    branchLink,
  } = useSelector((state) => state.globals);

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const user = getLocalStorageUser();
  const currentUserId = user?._id;

  useEffect(() => {
    if (notificationModal === MODAL_TYPES.NOTIFICATION_MODE) {
      dispatch({
        type: GET_BRANCH_URL,
        payload: {
          title: name,
          uCode: groupCode,
          icon,
        },
      });

      dispatch({
        type: GET_BRANCH_WEB_URL,
        payload: {
          title: name,
          link: "auth/login",
          icon,
        },
      });
    }
  }, [dispatch, groupCode, icon, name, notificationModal]);

  useEffect(() => {
    if (status === 200 || status === 206) {
      toast.success(message);
      setModal(MODAL_INIT_STATE);
      dispatch(setLeaveGroupStatus({}));
      navigate("/groups", {
        replace: true,
      });
    }
  }, [status]);

  useEffect(() => {
    if (notiStatus) setNotificationModal(MODAL_TYPES.CONFIRMATION);
  }, [notiStatus]);

  useEffect(() => {
    if (highlight)
      window.history.replaceState(
        null,
        null,
        "/settings/participants?groupId=" + params.get("groupId")
      );
  }, []);

  const analyticsEnabled = useSubscription().hasAccessToFeature(
    PLAN_FEATURES.view_analytics
  );

  const resetModal = () => {
    setModal(MODAL_INIT_STATE);
  };

  const transferOwnership = () => {
    resetModal();
    navigate("/settings/participants?groupId=" + params.get("groupId"), {
      state: {
        highlight: true,
      },
      replace: true,
    });
  };

  const handleGroup = async (shouldDelete) => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: {
        buttons: [
          shouldDelete
            ? ANALYTICS.PARTICIPANT_DELETE
            : ANALYTICS.PARTICIPANT_LEAVE,
        ],
      },
    });

    if (!participants.length) return;

    setLoading(true);
    const isUploading = await isParallelyUploading();
    const isUploadingLink = await uploadingFromLink();
    if (isUploading || isUploadingLink) {
      setLoading(false);
      toast.error("Images are uploading. Please wait for it to complete.");
      return;
    } else {
      setLoading(false);

      let modalState = {
        show: true,
        type: shouldDelete ? "delete" : "leave",
      };

      let { title, message } = getConfirmationModalText(
        participants,
        modalState.type,
        isAdmin,
        isPrivate,
        user?.userType === "PHOTOGRAPHER"
      );

      modalState.title = title;
      modalState.message = message;

      if (shouldDelete) {
        if (
          participants?.length > 1 &&
          createdBy === currentUserId &&
          shouldShowTransferInfo(2)
        ) {
          modalState.cancelButtonText = "Delete Group";
          modalState.confirmButtonText = "Transfer Ownership";

          modalState.cancelButton = () => handleGroup(true);
          modalState.confirmButton = transferOwnership;
          modalState.showTitle = false;
        } else {
          modalState.cancelButtonText =
            participants?.length > 1 ? "Ok" : "Cancel";
          modalState.confirmButtonText =
            participants?.length > 1 ? "" : "Delete";

          modalState.cancelButton = resetModal;
          modalState.confirmButton = () =>
            handleLeaveGroup(
              dispatch,
              PATCH_LEAVE_GROUP,
              leaveParticipantSettings
            );

          modalState.showTitle = true;
        }
      } else {
        modalState.cancelButtonText = "Cancel";
        modalState.confirmButtonText = "Confirm";

        if (
          modalState.message.includes("remove all") ||
          modalState.message.includes("make someone else admin")
        ) {
          modalState.cancelButtonText = "Ok";
          modalState.confirmButtonText = "";
        }

        modalState.cancelButton = resetModal;
        modalState.confirmButton = () =>
          handleLeaveGroup(
            dispatch,
            PATCH_LEAVE_GROUP,
            leaveParticipantSettings
          );

        modalState.showTitle = true;
      }

      setModal(modalState);
    }
  };

  return (
    <div>
      <SettingPageHeading
        title="Participants"
        subTitle={
          <>
            <img
              src="/assets/images/icons/info-plain.png"
              alt="info plain"
              className="me-1 info-icon cursor-pointer"
            />
            It is recommended to make hosts, Bride & Groom etc as Admin.
          </>
        }
        btnTitle="Leave Group"
        btnTitle2="Delete Group"
        btnThree={
          leaveParticipantSettings?.isAdmin && (
            <IconedButton2
              icon="/assets/images/icons/notifications/bell.png"
              title="Notify"
              icon2="/assets/images/icons/new.png"
              icon2Class={css["new-icon"]}
              className={classNames(
                "font-14",
                "w-130",
                "justify-content-center",
                css["notification-btn"],
                "position-relative"
              )}
              onClick={() => {
                setNotificationModal(MODAL_TYPES.NOTIFICATION_MODE);
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.PARTICIPANT_NOTIFY] },
                });
              }}
            />
          )
        }
        btnGrpClass={css["btn-grp"]}
        onClick={() => handleGroup(false)}
        onClick2={() => handleGroup(true)}
        btnClass="w-130 px-0 justify-content-center"
        btnClass2="danger w-130 px-0 justify-content-center"
        participantsLength={noOfParticipants || participants?.length}
        showSecond={leaveParticipantSettings.isAdmin}
        goBack={token && !noback ? () => history.back() : false}
      />

      {noOfParticipants > 10 && (
        <div className={css.search}>
          <Input
            height="sm"
            variant="secondary"
            placeholder="Type to search"
            onChange={debounce((e) => setSearchText(e.target.value), 600)}
          />

          <img
            src="/assets/images/icons/search.png"
            alt="Search"
            title="Click to search"
          />
        </div>
      )}

      <div className="participantsTable">
        <ParticipantsTable
          admin={leaveParticipantSettings.isAdmin}
          analyticsEnabled={analyticsEnabled}
          highlight={highlight}
          search={searchText.trim().toLowerCase()}
        />
      </div>

      <ConfirmationModal
        title={modal?.showTitle ? modal?.title : false}
        show={modal?.show}
        message={modal?.showTitle ? modal?.message : null}
        onCancel={modal?.cancelButton}
        onConfirm={modal?.confirmButton}
        onExit={resetModal}
        cancelText={modal?.cancelButtonText}
        confirmText={modal?.confirmButtonText}
        animation={true}
        className={modal?.showTitle ? "" : "transfer-modal"}
        cancelClass={modal?.showTitle ? "px-5" : "px-4 transfer"}
        confirmClass={modal?.showTitle ? "" : "px-4 transfer"}
        footerClass="pt-0"
      >
        {!modal?.showTitle && (
          <>
            <>
              <img
                src="/assets/images/icons/transfer-ownership.jpg"
                alt="Transfer Ownership"
                className="w-100 cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=07UlKexpI7Q",
                    "_blank"
                  )
                }
              />

              <h2>
                Want to free up storage space?
                <br />
                Try the transfer ownership feature
              </h2>

              <p>
                Note: It will remove the photos from your storage.
                <br />
                Group members can still access/view the photos.
              </p>
            </>
          </>
        )}
      </ConfirmationModal>

      <ConfirmationModal
        show={notificationModal === MODAL_TYPES.NOTIFICATION_MODE}
        onCancel={() => setNotificationModal(MODAL_TYPES.NONE)}
        onConfirm={() => setNotificationModal(MODAL_TYPES.USER_LIST)}
        title="Choose Mode of Notification"
        cancelText=""
        confirmText="Continue"
        animation={true}
      >
        <div className="d-flex gap-4 flex-wrap justify-content-center">
          {/* <Card
            className={classNames(
              css["mode-select"],
              notifications == NOTIFICATION_MODES.SMS ? css.active : ""
            )}
            onClick={() =>
              setNotifications(NOTIFICATION_MODES.SMS)
            }
          >
            <img src="/assets/images/icons/notifications/sms.png" alt="sms" />
            <p className="font-bold font-15 text-center color-secondary m-0">
              SMS
            </p>
            <p className="font-11 m-0 text-center">0.5 credit/notif</p>
          </Card> */}

          <Card
            className={classNames(
              css["mode-select"],
              notificationsMode === NOTIFICATION_MODES.WHATSAPP
                ? css.active
                : ""
            )}
            onClick={() => setNotificationsMode(NOTIFICATION_MODES.WHATSAPP)}
          >
            <img
              src="/assets/images/icons/notifications/whatsapp.png"
              alt="whatsapp"
            />
            <p className="font-bold font-15 text-center color-secondary m-0">
              WhatsApp
            </p>
            <p className="font-11 m-0 text-center">1 credit/notif</p>
          </Card>

          <Card
            className={classNames(
              css["mode-select"],
              notificationsMode === NOTIFICATION_MODES.EMAIL ? css.active : ""
            )}
            onClick={() => setNotificationsMode(NOTIFICATION_MODES.EMAIL)}
          >
            <img
              src="/assets/images/icons/notifications/email.png"
              alt="email"
            />
            <p className="font-bold font-15 text-center color-secondary m-0">
              Email
            </p>
            <p className="font-11 m-0 text-center">0.2 credit/notif</p>
          </Card>
        </div>
      </ConfirmationModal>

      <React.Suspense fallback={<SuspenseLoader />}>
        {notificationModal === MODAL_TYPES.USER_LIST && (
          <SelectUserList
            show={true}
            handleClose={() => setNotificationModal(MODAL_TYPES.NONE)}
            selectedUsers={[]}
            getSelectedUsers={(users) => {
              if (users.length === 0)
                return toast.error("Please select atleast one user");

              dispatch({
                type: SEND_NOTIFICATIONS,
                payload: {
                  groupId: params.get("groupId"),
                  campaignType: notificationsMode,
                  users,
                  websiteUrl: branchLink + "?uCode=" + groupCode,
                  mobileUrl: branchURL,
                },
              });
              setNotificationModal(MODAL_TYPES.NONE);
            }}
            users={participants?.filter(
              (p) =>
                p.participantId !== currentUserId &&
                (notificationsMode === NOTIFICATION_MODES.EMAIL
                  ? p.email
                  : p.phoneNumber)
            )}
            showCheckAll={true}
            btnTitle="Notify"
          />
        )}
      </React.Suspense>

      <ConfirmationModal
        show={notificationModal === MODAL_TYPES.CONFIRMATION}
        onConfirm={() => {
          dispatch(clearNotificationResponse());
          navigate("/profile-settings/wallet");
        }}
        onCancel={() => {
          dispatch(clearNotificationResponse());
          setNotificationModal(MODAL_TYPES.NONE);
        }}
        cancelText={notiStatus === 200 ? "" : "No"}
        confirmText={notiStatus === 200 ? "" : "Yes"}
        title={notiStatus === 400 ? "Insufficient Credits" : ""}
        footer={notiStatus === 400}
        animation={true}
        size={notiStatus === 200 ? "sm" : "md"}
      >
        {notiStatus === 200 ? (
          <div>
            <img
              src="/assets/images/icons/tick-green.png"
              width={56}
              alt="green-tick"
              className="text-center mx-auto d-block mb-2"
            />

            <p className="font-24 color-primary font-bold">Notified!</p>
          </div>
        ) : notiStatus === 400 ? (
          <p className="font-15 text-center">
            You have insufficient credit balance. Do you want to add credits in
            your wallet?
          </p>
        ) : null}
      </ConfirmationModal>

      <Spinner loading={loading || saveLoader} />
    </div>
  );
};

export default Participants;

export const handleLeaveGroup = (
  dispatch,
  PATCH_LEAVE_GROUP,
  leaveParticipantSettings
) => {
  dispatch({
    type: PATCH_LEAVE_GROUP,
    groupId: leaveParticipantSettings.groupID,
  });
};

export const getConfirmationModalText = (
  participants,
  type,
  isAdmin,
  isPrivate,
  isPhotoGrapher
) => {
  const admins = participants.filter((Participants) => {
    return Participants.isAdmin === true;
  });
  const participantsCount = participants.length;
  const adminsCount = admins.length;

  if (isAdmin) {
    if (adminsCount === 1) {
      if (participantsCount > 1) {
        return {
          title: (
            <span className="text-danger">
              Error in {type === "leave" ? "leaving" : "deleting"} group!
            </span>
          ),
          message:
            isPhotoGrapher && type === "leave"
              ? "You cannot leave the group because you are the only admin. Please make someone else admin first."
              : `Please remove all the participants before ${
                  type === "leave" ? "leaving" : "deleting"
                } this group.`,
        };
      } else {
        return {
          title: `Are you sure you want to ${type} this group?`,
          message: "This group and all its images will be deleted.",
        };
      }
    } else if (type === "leave") {
      return {
        title: `Are you sure you want to leave this group?`,
        message: "You will not be able to see photos from this group anymore.",
      };
    } else {
      return {
        title: <span className="text-danger">Error in deleting group!</span>,
        message: `Please remove all the participants before deleting this group.`,
      };
    }
  } else {
    if (participantsCount === 1 && isPrivate) {
      return {
        title: `Are you sure you want to ${type} this group?`,
        message: "This group and all its images will be deleted.",
      };
    } else {
      return {
        title: `Are you sure you want to ${type} this group?`,
        message: "You will not be able to see photos from this group anymore.",
      };
    }
  }
};
