import { takeLatest, put, call, delay } from "redux-saga/effects";
import { AXIOS, AXIOS2 } from "../../utils/setup/axios";
import { toast } from "react-toastify";

import {
  handleUserRegisteration,
  handleVerifyOTP,
  handleRegisterPhotographerProfile,
  setLoading,
  handleUploadAvatarResponse,
  setPassSetResponse,
} from "../slices/auth";
import {
  REGISTER_REQUEST,
  VERIFY_OTP_REQUEST,
  REGISTER_PHOTOGRAPHER_PROFILE,
  // SET_USER_PROFILE,
  VERIFY_LOGOUT_REQUEST,
  UPLOAD_AVATAR,
  RESEND_OTP,
  VERIFY_PASSWORD,
  SET_PASSWORD,
} from "./saga-actions";
import {
  setSettingLoader,
  setUserProfileData,
} from "redux-store/slices/settings";
import ENV from "utils/helpers/env";
import { lsProxy } from "utils/helpers/localstorage";
import generateCipher from "utils/helpers/generateCipher";

const REGISTER_MAX_RETRIES = 3;
const RESEND_OTP_MAX_RETRIES = 3;

async function registerApi(payload) {
  const cipher = generateCipher();

  return AXIOS.post(
    "api/app/auth/register",
    {
      ...payload,
      ...(ENV.WL_DEVICE_APP && { deviceApp: ENV.WL_DEVICE_APP }),
    },
    { headers: { cipher } }
  );
}

function* registerRequestWithRetries(payload) {
  for (let retries = 0; retries < REGISTER_MAX_RETRIES; retries++) {
    try {
      const res = yield call(registerApi, payload);
      return res;
    } catch (e) {
      if (
        e.response.data?.message === "This request has expired" &&
        retries !== REGISTER_MAX_RETRIES - 1
      ) {
        yield delay(500);
      } else {
        throw e;
      }
    }
  }
}

async function verifyLogoutRequest() {
  return AXIOS.post("api/app/auth/logout");
}

function* registerRequestGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(registerRequestWithRetries, action.payload);
    yield put(handleUserRegisteration(response?.data));
  } catch (e) {
    toast.error(e?.response?.data?.error?.[0] || e?.response?.data?.message);
    yield put(handleUserRegisteration(e.response?.data)); // this is to handle the case when we can't send OTP to the selected country
  } finally {
    yield put(setLoading(false));
  }
}

async function verifyOTPRequest(action) {
  const cipher = generateCipher();
  const _id = Date.now() + "";

  return AXIOS.post(
    "api/app/auth/verify-otp-login",
    {
      ...action?.payload,
      deviceId: _id,
      deviceType: "WEB",
      deviceToken: "Token-" + _id,
      ...(ENV.WL_DEVICE_APP && { deviceApp: ENV.WL_DEVICE_APP }),
    },
    { headers: { cipher } }
  );
}

async function registerPhotographerProfile(action) {
  const { token, ...payload } = action.payload;
  return AXIOS.post("api/app/user/set-photographer-profile", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function* verifyOTPRequestGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(verifyOTPRequest, action);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(handleVerifyOTP(response?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setLoading(false));
  }
}

function* registerPhotographerProfileGenerator(action) {
  try {
    const response = yield call(registerPhotographerProfile, action);
    yield put(handleRegisterPhotographerProfile(response?.data));
  } catch (e) {
    yield put(setLoading(false));
    toast.error(e?.response?.data?.message);
  }
}

function* verifyLogoutRequestGenerator() {
  try {
    yield put(setLoading(true));
    const response = yield call(verifyLogoutRequest);
    if (response?.status === 200) {
      lsProxy.clear();
      window.location.replace("/auth/login");
    }
  } catch (e) {
    toast.error("Logout Failed");
  } finally {
    yield put(setLoading(false));
  }
}

async function uploadAvatar(action) {
  const { avatar, token } = action?.payload || {};

  const formData = new FormData();
  formData.append("avatar", avatar);

  return AXIOS2.put("api/app/user/upload-avatar", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

function* uploadAvatarGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(uploadAvatar, action);
    if (response?.status === 200 || response?.status === 201) {
      toast.success(response?.data?.message || "Avatar Uploaded Successfully");
    }
    yield put(handleUploadAvatarResponse(response?.data));
  } catch (e) {
    yield put(handleUploadAvatarResponse(e?.response?.data));
    toast.error(e?.response?.data?.reason);
  } finally {
    yield put(setLoading(false));
  }
}

async function resendOTP(action) {
  const cipher = generateCipher();

  return AXIOS.post(
    "api/app/auth/resend-otp",
    {
      ...action?.payload,
      ...(ENV.WL_DEVICE_APP && { deviceApp: ENV.WL_DEVICE_APP }),
    },
    { headers: { cipher } }
  );
}

function* resendOTPRequestWithRetries(payload) {
  for (let retries = 0; retries < RESEND_OTP_MAX_RETRIES; retries++) {
    try {
      const res = yield call(resendOTP, payload);
      return res;
    } catch (e) {
      if (
        e.response.data?.message === "This request has expired" &&
        retries !== RESEND_OTP_MAX_RETRIES - 1
      ) {
        yield delay(500);
      } else {
        throw e;
      }
    }
  }
}

function* resendOTPGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(resendOTPRequestWithRetries, action);
    if (response?.status === 200 || response?.status === 201) {
      toast.success(response?.data?.message);
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setLoading(false));
  }
}

async function verifyPassword(data) {
  const cipher = generateCipher();
  const _id = Date.now() + "";
  const { password, countryCode, phoneNumber, email } = data || {};

  return AXIOS.post(
    "/api/app/auth/verify-password",
    {
      password,
      countryCode,
      phoneNumber,
      email,
      deviceId: _id,
      deviceType: "WEB",
      deviceToken: "Token-" + _id,
      ...(ENV.WL_DEVICE_APP && { deviceApp: ENV.WL_DEVICE_APP }),
    },
    { headers: { cipher } }
  );
}

function* verifyPasswordGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(verifyPassword, action?.payload);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(handleVerifyOTP(response?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setLoading(false));
  }
}

async function setPassword(data) {
  const { password } = data || {};

  return AXIOS.post("/api/app/auth/set-password", {
    password,
  });
}

function* setPasswordGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    // eslint-disable-next-line no-unused-vars
    const response = yield call(setPassword, action?.payload);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(setPassSetResponse(response?.data));
    yield put(
      setUserProfileData({
        data: response?.data?.data,
        status: response?.data?.status,
      })
    );
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSettingLoader(false));
  }
}

export function* authSaga() {
  yield takeLatest(REGISTER_REQUEST, registerRequestGenerator);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOTPRequestGenerator);
  yield takeLatest(VERIFY_LOGOUT_REQUEST, verifyLogoutRequestGenerator);
  yield takeLatest(
    REGISTER_PHOTOGRAPHER_PROFILE,
    registerPhotographerProfileGenerator
  );
  yield takeLatest(UPLOAD_AVATAR, uploadAvatarGenerator);
  yield takeLatest(RESEND_OTP, resendOTPGenerator);
  yield takeLatest(VERIFY_PASSWORD, verifyPasswordGenerator);
  yield takeLatest(SET_PASSWORD, setPasswordGenerator);
}
