import { SocketContext } from "context/socket";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearJoinGroupStatus } from "redux-store/slices/group";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { goToInitialGallaryRoute } from "utils/helpers/routes";

export default function useJoinGroupInfo() {
  const socket = React.useContext(SocketContext);
  const timerRef = React.useRef();
  const [loading, setLoading] = React.useState();
  const joinGroup = useSelector((state) => state.groups);
  const groupDetails = useSelector((state) => state.groups.group?.data);
  const isLoggedIn = !!lsProxy.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGroupJoinSuccess = (groupId) => {
    setLoading(false);
    clearTimeout(timerRef.current);
    dispatch(clearJoinGroupStatus());
    navigate(goToInitialGallaryRoute(groupId));
  };

  // whitelabel specific, to show error that you can't join this group
  const handleJoinFailed = () => {
    setLoading(false);
    clearTimeout(timerRef.current);
    dispatch(clearJoinGroupStatus());
    navigate("/groups", { state: { isJoinFailed: true } });
  };

  // manually causing a delay to give time for the socket to connect
  async function joinNewGroupSocketInit() {
    setLoading(true);
    return await new Promise((resolve) => setTimeout(resolve, 300));
  }

  useEffect(() => {
    socket?.on("joinGroupInfo", (resp) => {
      if (resp?.status === 206) {
        if (resp?.message === "No photos in group") {
          handleGroupJoinSuccess(resp?.data?.groupId);
        }
      } else {
        handleGroupJoinSuccess(resp?.data?.groupId);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (groupDetails?.message === "Invalid groupCode" && !isLoggedIn) {
      navigate("/invalid-group-link");
    }
  }, [groupDetails?.message]);

  useEffect(() => {
    if (joinGroup?.joinGroupResponse) {
      if (
        joinGroup?.joinGroupResponse?.status === 200 &&
        joinGroup?.joinGroupResponse?.message.includes(
          "Group joined successfully"
        )
      ) {
        const { userType = "" } = getLocalStorageUser();

        if (userType.toUpperCase() === "PHOTOGRAPHER") {
          handleGroupJoinSuccess(joinGroup?.joinGroupResponse?.groupId);
          return;
        }
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          handleGroupJoinSuccess(joinGroup?.joinGroupResponse?.groupId);
        }, 10000);
      } else if (joinGroup?.joinGroupResponse?.status === 400) {
        if (
          joinGroup?.joinGroupResponse?.message ===
            "You are already group participant" ||
          joinGroup?.joinGroupResponse?.message ===
            "Full view access granted successfully."
        ) {
          handleGroupJoinSuccess(joinGroup?.joinGroupResponse?.groupId);
        } else if (
          joinGroup?.joinGroupResponse?.message === "Invalid group code"
        ) {
          navigate("/invalid-group-link");
        } else if (
          joinGroup?.joinGroupResponse?.message ===
          "Cannot join the group. User selfie is not processed yet."
        ) {
          setLoading(false);
        }
      } else if (joinGroup?.joinGroupResponse?.status === 403) {
        if (
          joinGroup?.joinGroupResponse?.message ===
          "Joining is not allowed in this group, please contact group admin"
        ) {
          setLoading(false);
        }
      } else if (joinGroup?.joinGroupResponse?.status === 211) {
        if (window.location.pathname?.includes("groups/join"))
          setLoading(false);
        else handleJoinFailed();
      } else if (joinGroup?.joinGroupResponse?.status === 401) {
        // reload page if token is expired
        window.location.reload();
      }
    }
  }, [joinGroup]);

  return {
    loading,
    joinNewGroupSocketInit,
  };
}
