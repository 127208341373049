import React from "react";
import { useSelector } from "react-redux";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";
import Lightbox from "yet-another-react-lightbox";
import { VIDEOS_PER_PAGE } from "views/gallary/gallaryPage/components/FolderVideos";
import { pushState } from "utils/helpers/debounced-history";

export default function AnonymousVideosModal({
  open,
  folderId,
  closeImageView,
  page,
  params,
  selectedID,
}) {
  const { allVideos: videos = [] } = useSelector((state) => state.folderImages);
  const initialIndex = React.useMemo(() => {
    let idx = videos?.findIndex(
      (item) => item?._id === (selectedID ?? params.get("videoId"))
    );

    return idx >= 0 ? idx : 0;
  }, [params, selectedID, videos]);
  const [vidIndex, setVideoIndex] = React.useState(initialIndex);

  React.useEffect(() => {
    const newPage = Math.floor(vidIndex / VIDEOS_PER_PAGE) + 1;

    if (newPage !== page) {
      pushState(
        null,
        "",
        modifyAnonymousGallaryRoute(
          { pageNo: newPage, videoId: videos[vidIndex]?._id },
          params
        )
      );
    } else {
      pushState(
        null,
        "",
        modifyAnonymousGallaryRoute({ videoId: videos[vidIndex]?._id }, params)
      );
    }
  }, [vidIndex, videos]);

  React.useEffect(() => {
    stopVideo();
  }, [vidIndex]);

  const videosData = React.useMemo(() => {
    if (folderId === "all-videos") {
      // let videoData = videos?.map((items) => {
      //   return {
      //     sources: [
      //       {
      //         src: items?.url,
      //         type: "video/mp4",
      //       },
      //     ],
      //     width: 1280,
      //     height: 720,
      //     type: "video",
      //     poster: items?.url,
      //   };
      // });

      const data = videos?.map((video, index) => {
        return { src: video?.url || "", index, type: "video" };
      });

      return data;
    }
  }, [folderId, videos]);

  var stopVideo = function () {
    var iframe = document.querySelector("iframe");
    if (iframe) {
      const message = JSON.stringify({ event: "command", func: "pauseVideo" });
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  return (
    <Lightbox
      key={videos[vidIndex]?._id}
      open={open}
      index={vidIndex}
      on={{
        view: ({ index }) => {
          setVideoIndex(index);
        },
      }}
      close={() => {
        closeImageView();
      }}
      slides={videosData}
      carousel={{
        preload: 0,
      }}
      render={{
        slide: ({ slide }) => {
          if (slide?.src) {
            if (slide?.src?.includes("youtube.com")) {
              return (
                <iframe
                  width="100%"
                  id={"youtube_player"}
                  className="yt_player_iframe"
                  height="100%"
                  src={`${slide?.src}?t=${new Date().valueOf()}`}
                  title="YouTube video player"
                  referrerPolicy="no-referrer-when-downgrade"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowscriptaccess="always" //eslint-disable-line
                ></iframe>
              );
            } else {
              return (
                <video
                  key={slide?.index}
                  width="100%"
                  height="100%"
                  className="_videos"
                  controls
                  onBlur={(e) => e.target.pause()}
                  nofullscreen //eslint-disable-line
                  playsInline
                >
                  <source src={slide?.src} type="video/mp4" />
                </video>
              );
            }
          }
        },
      }}
      toolbar={{
        buttons: [
          <div key={folderId} className="anon-toolbar">
            <img
              onClick={closeImageView}
              src="/assets/icons/arrow.svg"
              alt="close"
              className="cursor-pointer"
            />
            <p className="text-white m-0">
              {vidIndex + 1}/{videos?.length}
            </p>
          </div>,
        ],
      }}
    />
  );
}
