/*****************Settings ******************/

export const GET_GROUP_DETAILS_AND_OTHER_SETTINGS =
  "GET_GROUP_DETAILS_AND_OTHER_SETTINGS";

export const GET_GROUP_DESIGNA_AND_SETTINGS = "GET_GROUP_DESIGNA_AND_SETTINGS";

export const PUT_GROUP_GENERAL_SETTINGS = "PUT_GROUP_GENERAL_SETTINGS";

export const PATCH_GROUP_PRIVACY_SETTINGS = "PATCH_GROUP_PRIVACY_SETTINGS";

export const PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN =
  "PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN";

export const PATCH_LEAVE_GROUP = "PATCH_LEAVE_GROUP";

export const DESIGN_GROUP_SETTINGS = "DESIGN_GROUP_SETTINGS";

export const POST_GROUP_DESIGN_AND_SETTING = "POST_GROUP_DESIGN_AND_SETTING";

export const GET_BUSINESS_BRANDING = "GET_BUSINESS_BRANDING";

export const POST_BUSINESS_BRANDING = "POST_BUSINESS_BRANDING";

export const POST_BUSINESS_LOGO = "POST_BUSINESS_LOGO";

export const POST_BUSINESS_WATERMARK = "POST_BUSINESS_WATERMARK";

export const POST_WATERMARK_OPTIONS = "POST_WATERMARK_OPTIONS";

export const DELETE_WATERMARK = "DELETE_WATERMARK";

export const PUT_COVER = "PUT_COVER";

export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const SEND_OTP_TO_UPDATE_PHONE_OR_EMAIL =
  "SEND_OTP_TO_UPDATE_PHONE_OR_EMAIL";

export const VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL =
  "VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL";

export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const RENAME_FOLDER = "RENAME_FOLDER";

export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";

export const GET_FAVOURITE_IMAGES = "GET_FAVOURITE_IMAGE";

export const GET_USER_ANALYTICS = "GET_USER_ANALYTICS";

export const SEND_USER_CLICK_ANALYTICS = "SEND_USER_CLICK_ANALYTICS";

export const GET_GROUP_ADMIN_TOKEN = "GET_GROUP_ADMIN_TOKEN";

export const CHANGE_FOLDER_ORDER = "CHANGE_FOLDER_ORDER";

export const POST_TRANSFER_FOLDER = "POST_TRANSFER_FOLDER";

export const DELETE_MY_ACCOUNT = "DELETE_MY_ACCOUNT";

export const ADD_CREDITS_TO_WALLET = "ADD_CREDITS_TO_WALLET";
export const GET_USER_WALLET = "GET_USER_WALLET";

/********************************************/

/******************Groups*****************/

export const GET_MY_GROUPS = "GET_GROUPS";
export const CREATE_MY_GROUP = "CREATE_MY_GROUP";
export const JOIN_MY_GROUP = "JOIN_MY_GROUP";
export const GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
export const DELETE_GROUP_ICON = "DELETE_GROUP_ICON";
export const CREATE_TRANSFER_REQUEST = "CREATE_TRANSFER_REQUEST";
export const GET_TRANSFER_DETAILS = "GET_TRANSFER_DETAILS";
export const ACCEPT_REJECT_TRANSFER = "ACCEPT_REJECT_TRANSFER";
export const GET_DELETE_REQUESTS = "GET_DELETE_REQUESTS";
export const ACCEPT_REJECT_DELETE_REQUEST = "ACCEPT_REJECT_DELETE_REQUEST";
export const SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS";
export const CANCEL_LINK_UPLOAD = "CANCEL_LINK_UPLOAD";
export const UPDATE_GROUP_ACCESS = "UPDATE_GROUP_ACCESS";
export const DELETE_REARRANGE_SPONSOR = "DELETE_REARRANGE_SPONSOR";
export const RESET_FULL_ACCESS = "RESET_FULL_ACCESS";

/*****************************************/

/******************Folders****************/

export const GET_FOLDERS = "GET_FOLDERS";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const VERIFY_DOWNLOAD_PIN = "VERIFY_DOWNLOAD_PIN";

/*****************************************/

/****************AUTH********************/

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_LOGOUT_REQUEST = "VERIFY_LOGOUT_REQUEST";
export const REGISTER_PHOTOGRAPHER_PROFILE = "REGISTER_PHOTOGRAPHER_PROFILE";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const RESEND_OTP = "RESEND_OTP";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const UPLOAD_ANONYMOUS_AVATAR = "UPLOAD_ANONYMOUS_AVATAR";
export const GET_ANONYMOUS_USER_IMAGES = "GET_ANONYMOUS_USER_IMAGES";

/****************************************/

/*****************Images********************/

export const GET_ALL_IMAGES_OF_GROUP = "GET_ALL_IMAGES_OF_GROUP";
export const GET_MY_IMAGES = "GET_MY_IMAGES";
export const GET_IMAGES_OF_GROUP = "GET_IMAGES_OF_GROUP";
export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";
export const DELETE_IMAGE_OF_FOLDER = "DELETE_IMAGE_OF_FOLDER";
export const IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS =
  "IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS";
export const CHANGE_FAVORITE_STATUS = "CHANGE_FAVORITE_STATUS";
export const ADD_IMAGE_COMMENT = "ADD_IMAGE_COMMENT";
export const DELETE_VIDEO_OF_FOLDER = "DELETE_VIDEO_OF_FOLDER";
export const UPLOAD_YOUTUBE_VIDEO = "UPLOAD_YOUTUBE_VIDEO";
export const GET_PAID_PICS = "GET_PAID_PICS";
export const GET_DELETED_IMAGES = "GET_DELETED_IMAGES";
export const UNDO_DELETE_IMAGES = "UNDO_DELETE_IMAGES";

/***************BULK OPERATIONS*************/

export const DELETE_PICS = "DELETE_PICS";
export const FAVORITE_PICS = "FAVORITE_PICS";

/*****************************************/

/*****************Analytics********************/

export const GET_MY_GROUP_ANALYTICS = "GET_MY_GROUP_ANALYTICS";

/*****************************************/

/**************** SUBSCRIPTION ***************/

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_MY_SUBSCRIPTION_PLAN = "GET_MY_SUBSCRIPTION_PLAN";
export const GET_PUBLIC_PLANS = "GET_PUBLIC_PLANS";
export const BUY_PREMIUM_FEATURE = "BUY_PREMIUM_FEATURE";

/*****************************************/

export const GET_BRANCH_URL = "GET_BRANCH_URL";
export const GET_BRANCH_WEB_URL = "GET_BRANCH_WEB_URL";

/************* PAYMENT ****************/
export const CREATE_ORDER = "CREATE_ORDER";
export const PAYMENT_LINK = "PAYMENT_LINK";
export const CREATE_ORDER_VISITOR = "CREATE_ORDER_VISITOR";
export const PAYMENT_VISITOR = "PAYMENT_VISITOR";
export const GET_BILLING_INFO = "GET_BILLING_INFO";
export const POST_BILLING_INFO = "POST_BILLING_INFO";

/************* DIGITAL ALBUM ****************/
export const GET_ALBUM_DETAILS = "GET_ALBUM_DETAILS";
export const UPLOAD_ALBUM_LOGO = "UPLOAD_ALBUM_LOGO";
export const CHANGE_ALBUM_IMAGE_ORDER = "CHANGE_ALBUM_IMAGE_ORDER";
export const DELETE_ALBUM_IMAGE = "DELETE_ALBUM_IMAGE";
export const UPDATE_ALBUM_COLOR = "UPDATE_ALBUM_COLOR";
export const UPDATE_ALBUM_PIN = "UPDATE_ALBUM_PIN";

/************* GLOBALS ****************/
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_STATES = "GET_ALL_STATES";
export const GET_CITIES = "GET_ALL_CITIES";
export const POST_CONTACTFORM = "POST_CONTACTFORM";

/************* PORTFOLIO ****************/
export const DELETE_RENAME_PORTFOLIO_FOLDER = "DELETE_RENAME_PORTFOLIO_FOLDER";
export const DELETE_PORTFOLIO_IMAGE = "DELETE_PORTFOLIO_IMAGE";
export const GET_PHOTOGRAPHER_PORTFOLIO = "GET_PHOTOGRAPHER_PORTFOLIO";
export const PUT_PHOTOGRAPHER_PORTFOLIO = "PUT_PHOTOGRAPHER_PORTFOLIO";
export const UPLOAD_SERVICE_ICON = "UPLOAD_SERVICE_ICON";

/************* CART ****************/
export const GET_CART = "GET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const GET_PURCHASED_ITEMS = "GET_PURCHASED_ITEMS";
export const PURCHASE_ITEMS = "PURCHASE_ITEMS";

/************* TEAM LOGIN *************** */
export const GET_DELEGATES = "GET_DELEGATES";
export const CREATE_DELETGATE = "CREATE_DELETGATE";
export const UPDATE_DELEGATE = "UPDATE_DELEGATE";
export const DELETE_DELEGATE = "DELETE_DELEGATE";

/************* NOTIFICATIONS *************** */
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_GROUPWISE_NOTIFICATIONS = "GET_GROUPWISE_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_GROUP = "GET_ALL_NOTIFICATIONS_GROUP";
