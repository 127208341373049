import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SettingPageHeading from "../components/SettingPageHeading";
import DefaultSwitch from "../../components/input/DefaultSwitch";
import SelectRadioGroup from "../../components/radio/SelectRadioGroup";
import Spinner from "../../components/loader/Spinner";
import { useSearchParams } from "react-router-dom";
import {
  PATCH_GROUP_PRIVACY_SETTINGS,
  POST_GROUP_DESIGN_AND_SETTING,
  RESET_FULL_ACCESS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import { deepEqual } from "utils/helpers";
import { checkArrows, scroll } from "views/gallary/gallaryPage/GallaryView";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { Col, Container, Row } from "react-bootstrap";
import SecondaryButton from "views/components/button/SmallRoundedButton";
import { PinCard } from "../DownloadSettings";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import { PAID_FEATURES } from "constants/plans";
import SubscriptionModal from "views/subscription";
import css from "./index.module.css";
import cx from "classnames";
import withTooltip from "hoc/withTooltip";
import ENV from "utils/helpers/env";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import PlainButton from "views/components/button/PlainButton";
import ANALYTICS from "constants/analyticsKeys";

const SelectUserList = React.lazy(() =>
  import("../../components/modals/SelectUserList")
);

const uploadPermissionOptions = [
  {
    value: true,
    heading: "All Participants",
    description: "Anyone part of the group can upload",
  },
  {
    value: false,
    heading: "Choose Participants",
    description: "Select participants who can upload",
  },
];

const photoAccessOptions = [
  {
    value: true,
    heading: "Small Personal Group",
    description: [
      { icon: "/assets/icons/tick-blue.png", text: "Face recognition" },
      {
        icon: "/assets/icons/tick-blue.png",
        text: "View all other photos and folders",
      },
    ],
  },
  {
    value: false,
    heading: "Big Public Group",
    description: [
      { icon: "/assets/icons/tick-blue.png", text: "Face recognition" },
      {
        icon: "/assets/icons/warning.png",
        text: "Admin can choose members who have all photos access",
      },
    ],
  },
];

const PrivacySettings = () => {
  const dispatch = useDispatch();
  const {
    privacySettings,
    saveLoader,
    settingLoader,
    participantsSettings: { participants },
    groupDetails: {
      canAnyoneChangeNameIcon,
      anyOneJoinWithLink,
      canAnyoneUploadPhotos,
      isPrivate,
      isDeletedFolderHidden,
      createdBy,
      guestFolderOnly,
    },
    downloadSettings: { anonymousAccessIsPaid },
  } = useSelector((state) => state.settings);

  const [settings, setSettings] = React.useState({});
  const [showUserList, setShowUserList] = useState(false);
  const [showResetInfo, setShowResetInfo] = useState(false);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  const scrollRef = useRef();
  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const hasAccess = useSubscription().hasAccessToFeature(
    PLAN_FEATURES.anonymous_viewing
  );

  const { userType, _id: currentUserId } = getLocalStorageUser();

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");
  const groupId = params.get("groupId");

  useEffect(() => {
    const ScrollWidth = scrollRef.current?.scrollWidth;
    const ClientWidth = scrollRef.current?.clientWidth;
    if (ClientWidth <= ScrollWidth) {
      setShowArrows((state) => ({ ...state, right: true }));
    }
  }, [participants]);

  useEffect(() => {
    const scrollEl = scrollRef.current;
    const interval = setInterval(() => {
      checkArrows(scrollEl, showArrows, setShowArrows);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  });

  usePreventUnsavedNavigation({
    message: "You have unsaved changes. Do you really want to leave this page?",
    block: !deepEqual(privacySettings, settings),
  });

  // useEffect(() => {
  //   dispatch(setSettingLoader(true));
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: groupId,
  //   });
  //   //   dispatch({
  //   //     type: GET_GROUP_DESIGNA_AND_SETTINGS,
  //   //     groupId: groupId,
  //   //   });
  // }, []);

  useEffect(() => {
    if (Object.keys(privacySettings).length > 0) {
      setSettings({
        ...privacySettings,
      });
    }
  }, [privacySettings]);

  useEffect(() => {
    if (
      settings.canAnyoneUploadPhotos === false &&
      (settings?.uploadParticipants || []).length === 0
    ) {
      setShowUserList(true);
    }
  }, [settings.canAnyoneUploadPhotos, settings.uploadParticipants]);

  // useEffect(() => {
  //   if (
  //     settings?.canAnyoneUploadPhotos === false &&
  //     settings?.uploadParticipants?.length === 0
  //   ) {
  //     setShow(true);
  //   }
  // }, [settings?.canAnyoneUploadPhotos]);

  /**
   * Need to handle the photo access rights
   */
  const handleSave = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.PRIVACY_SAVE] },
    });
    // if ("anonymousAccess" in settings) {
    //   dispatch({
    //     type: "POST_GROUP_DESIGN_AND_SETTING",
    //     payload: {
    //       source: "privacy",
    //       groupId: groupId,
    //       anonymousAccess: settings.anonymousAccess,
    //     },
    //   });
    // }
    dispatch({
      type: PATCH_GROUP_PRIVACY_SETTINGS,
      payload: {
        ...settings,
        uploadParticipants: settings.uploadParticipants.join(","),
      },
    });
  };

  const handleUploadRadioChange = (value) => {
    const selectedValueIndex = uploadPermissionOptions.findIndex(
      (option) => option.value === value
    );

    setSettings({
      ...settings,
      canAnyoneUploadPhotos: uploadPermissionOptions[selectedValueIndex].value,
    });
  };

  const handleSelectRadioChange = (value) => {
    const selectedValueIndex = photoAccessOptions.findIndex(
      (option) => option.value === value
    );
    setSettings({
      ...settings,
      isPrivate: photoAccessOptions[selectedValueIndex].value,
    });
  };

  const handleToggle = (key) => {
    dispatch({
      type: PATCH_GROUP_PRIVACY_SETTINGS,
      payload: {
        ...settings,
        [key]: !privacySettings[key],
        uploadParticipants: settings.uploadParticipants.join(","),
      },
    });
  };

  const handleToggleAnonymousAccess = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.PRIVACY_ANONYMOUS_ACCESS_TOGGLE] },
    });

    if (currentUserId === createdBy && !hasAccess && !anonymousAccessIsPaid) {
      return setSubscriptionModal(true);
    }

    dispatch({
      type: POST_GROUP_DESIGN_AND_SETTING,
      payload: {
        source: "privacy",
        groupId,
        anonymousAccess: !settings.anonymousAccess,
      },
    });
  };

  const handleResetFullAccess = () => {
    dispatch({
      type: RESET_FULL_ACCESS,
      payload: { groupId },
    });
    setShowResetInfo(false);
  };

  return (
    <>
      <Spinner loading={saveLoader || settingLoader} />

      <SettingPageHeading
        title="Privacy Setting"
        btnTitle="Save"
        onClick={handleSave}
        goBack={token && !noback ? () => history.back() : false}
      />
      <Container fluid className="g-0">
        <Row>
          <Col lg={6} className="mt-4">
            <div className="d-flex align-items-center mb-2 justify-content-between w-xl-400">
              <h3 className="mb-0 me-2 font-16">
                Anyone can change Name and Icon
              </h3>
              <DefaultSwitch
                isChecked={canAnyoneChangeNameIcon}
                onChange={() => {
                  handleToggle("canAnyoneChangeNameIcon");
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.PRIVACY_NAME_ICON_TOGGLE] },
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mb-2 justify-content-between w-xl-400">
              <h3 className="mb-0 me-2 font-16">Anyone with link can join</h3>
              <DefaultSwitch
                isChecked={anyOneJoinWithLink}
                onChange={() => {
                  handleToggle("anyOneJoinWithLink");
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: {
                      buttons: [ANALYTICS.PRIVACY_ANYONE_JOIN_TOGGLE],
                    },
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mb-2 justify-content-between w-xl-400">
              <h3 className="mb-0 me-2 font-16">Hide deleted folders</h3>
              <DefaultSwitch
                isChecked={isDeletedFolderHidden}
                onChange={() => {
                  handleToggle("isDeletedFolderHidden");
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: {
                      buttons: [ANALYTICS.PRIVACY_HIDE_DELETED_TOGGLE],
                    },
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mb-2 justify-content-between w-xl-400">
              <h3 className="mb-0 me-2 font-16 d-flex align-items-center gap-2">
                <span>&quot;Guest Uploads&quot; folder</span>
                {withTooltip(
                  <img
                    src="/assets/images/icons/info-plain.png"
                    alt="info"
                    height={15}
                  />,
                  'Non-admins will only be able to upload in "Guest Uploads" folder'
                )}
              </h3>
              <DefaultSwitch
                isChecked={guestFolderOnly}
                onChange={() => {
                  handleToggle("guestFolderOnly");
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: {
                      buttons: [ANALYTICS.PRIVACY_GUEST_UPLOADS_TOGGLE],
                    },
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className="dropdown-divider mt-3 mb-4"></div>

        <Row>
          <Col lg={6}>
            <SelectRadioGroup
              title="Photo Access"
              selectedValue={settings?.isPrivate ?? isPrivate}
              // need to know the exact field for this
              onChange={(value) => handleSelectRadioChange(value)}
              options={photoAccessOptions}
              recommended={userType === "PHOTOGRAPHER" ? 1 : -1}
              titleClassName="d-flex align-items-center gap-2 mb-3"
              extra={
                !isPrivate && (
                  <PlainButton
                    variant="xss"
                    rounded={false}
                    onClick={() => setShowResetInfo(true)}
                    className="ms-auto d-flex align-items-center gap-2"
                  >
                    <img src="/assets/icons/refresh.png" height={13} />
                    <span className="font-14">Reset Full Access</span>
                  </PlainButton>
                )
              }
            />
          </Col>
        </Row>

        <div className="dropdown-divider mt-3 mb-4"></div>

        <Row>
          <Col lg={6}>
            <SelectRadioGroup
              title="Upload Permission"
              onChange={(value) => handleUploadRadioChange(value)}
              selectedValue={
                settings?.canAnyoneUploadPhotos ?? canAnyoneUploadPhotos
              }
              options={uploadPermissionOptions}
            />
          </Col>
        </Row>

        {!settings.canAnyoneUploadPhotos && (
          <>
            {(settings?.uploadParticipants || [])?.length > 0 && (
              <div className={css["selected-users-wrapper"]}>
                <div className={css["selected_users-list"]} ref={scrollRef}>
                  {(settings?.uploadParticipants || []).map((userId) => {
                    const user = (participants || []).find(
                      (participant) => participant.participantId === userId
                    );
                    if (user) {
                      return (
                        <div className={css["selected_user"]} key={userId}>
                          <img
                            src={
                              user?.avatar || "/assets/images/icons/user.png"
                            }
                            alt={user?.name || "user name"}
                            width={40}
                          />
                          <h4 className="text-clip w-80px font-15">
                            {user?.name || "User"}
                          </h4>
                        </div>
                      );
                    }
                  })}
                  {showArrows.left && (
                    <button
                      onClick={() =>
                        scroll(
                          0.8,
                          -1,
                          scrollRef?.current,
                          showArrows,
                          setShowArrows
                        )
                      }
                      className={cx(
                        css["participants-caret"],
                        css["left-participants-caret"]
                      )}
                    >
                      <img
                        src="/assets/images/icons/left-caret.svg"
                        alt="caret"
                      />
                    </button>
                  )}
                  {showArrows.right && (
                    <button
                      onClick={() =>
                        scroll(
                          0.8,
                          1,
                          scrollRef?.current,
                          showArrows,
                          setShowArrows
                        )
                      }
                      className={cx(
                        css["participants-caret"],
                        css["right-participants-caret"]
                      )}
                    >
                      <img
                        src="/assets/images/icons/left-caret.svg"
                        alt="caret"
                      />
                    </button>
                  )}
                </div>
              </div>
            )}
            <Row>
              <Col lg={6}>
                <SecondaryButton
                  title="Add +"
                  variant="xss"
                  rounded={false}
                  onClick={() => setShowUserList(true)}
                  className="mt-3 mb-2 ms-auto px-4"
                />
              </Col>
            </Row>
          </>
        )}

        <div className="dropdown-divider mt-3 mb-4"></div>

        <Row>
          <Col lg={6}>
            <div className="d-flex align-items-center mb-2">
              <h3 className="mb-0 font-16">Anonymous Viewing</h3>
              <div className="ms-2" />
              <DefaultSwitch
                isChecked={
                  settings?.anonymousAccess ?? privacySettings?.anonymousAccess
                }
                onChange={handleToggleAnonymousAccess}
              />
            </div>
            <p className="gray-text thick-font">
              Users can join and view group photos without Login
            </p>

            {settings?.anonymousAccess ?? privacySettings?.anonymousAccess ? (
              <PinCard
                showPinText={false}
                pinClassName="text-truncate d-block w-130"
                containerClassName="w-fit"
                pin={`${
                  ENV.WL_HOST || window.location.origin
                }/anonymous/gallery?groupId=${groupId}&anonymousUserId=${groupId}&folderId=all-photos&page=1`}
              />
            ) : null}
          </Col>
        </Row>

        {/* <div className="col-md-12 mt-4">
          <div className="dropdown-divider"></div>
        </div>

        <div className="col-md-6">
          <button onClick={handleOpenModal}>Select User Modal</button>
          <SelectUserList setShow={show} handleClose={handleCloseModal} />

          <button onClick={handleOpenPhoneModal}>Enter Phone Modal</button>
          <EnterPhoneModal
            setShow={showPhoneModal}
            handleClose={handleClosePhoneModal}
          />
        </div> */}
      </Container>

      <React.Suspense fallback={<SuspenseLoader />}>
        {showUserList && (
          <SelectUserList
            show={true}
            handleClose={() => setShowUserList(false)}
            selectedUsers={settings?.uploadParticipants || []}
            getSelectedUsers={(users) => {
              const selectedUsers = [...new Set([...users, currentUserId])];

              setSettings((prev) => {
                return {
                  ...prev,
                  uploadParticipants: selectedUsers,
                };
              });
              setShowUserList(false);
            }}
            users={participants}
            showCheckAll={true}
          />
        )}
      </React.Suspense>

      {showResetInfo && (
        <ConfirmationModal
          show={true}
          onCancel={() => setShowResetInfo(false)}
          onConfirm={handleResetFullAccess}
          title="Reset Full Access"
          buttonRounded={false}
          confirmText="Reset"
          message={
            <>
              The old Full Access invites will become invalid. Users joining the
              group with the old links/QR will only get Partial Access.
              <br />
              Are you sure you want to reset?
            </>
          }
          animation
        />
      )}

      {subscriptionModal && (
        <SubscriptionModal
          isOpen={true}
          onClose={() => setSubscriptionModal(false)}
          groupId={groupId}
          src={PAID_FEATURES.anonymous_viewing}
        />
      )}
    </>
  );
};

export default PrivacySettings;
