import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../modals/ConfirmationModal";
import CustomTable from "./Table";
import { setFolderLoader } from "../../../redux-store/slices/folders";
import Spinner from "../loader/Spinner";
import RenameFolderModal from "views/components/modals/RenameFolderModal";
import { useState } from "react";
import {
  getLocalStorageUser,
  shouldShowTransferInfo,
} from "utils/helpers/localstorage";
import { useNavigate } from "react-router-dom";
import withTooltip from "hoc/withTooltip";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import ANALYTICS from "constants/analyticsKeys";

const MODAL = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  NONE: "NONE",
};

const FoldersTable = () => {
  const [modalState, setModalState] = React.useState({
    status: MODAL.NONE,
    id: null,
    shouldShowTransferInfo: null,
  });
  const { folders, loader, initialLoader } = useSelector(
    (state) => state.folders
  );
  const {
    participantsSettings: { participants },
    groupDetails: { createdBy },
  } = useSelector((state) => state.settings);

  const { _id: creatorId } = getLocalStorageUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [media, setMedia] = useState(
    window.matchMedia("(max-width: 512px)").matches
  );

  useEffect(() => {
    window.matchMedia("(max-width: 512px)").addEventListener("change", (e) => {
      setMedia(e.matches);
    });
  }, []);

  const columns = [
    {
      dataKey: "name",
      width: media ? 400 : 300,
    },
    ...(!media
      ? [
          {
            dataKey: "count",
            width: 200,
          },
        ]
      : []),
    {
      dataKey: "edit",
      width: media ? 100 : 200,
    },
    {
      dataKey: "delete",
      width: media ? 30 : 200,
    },
  ];

  const handleDeleteFolder = (folderID) => {
    setModalState({
      status: MODAL.DELETE,
      id: folderID,
      shouldShowTransferInfo:
        participants?.length > 1 &&
        createdBy == creatorId &&
        shouldShowTransferInfo(1),
    });

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.FOLDER_DELETE] },
    });
  };

  const handleRenameFolder = ({ folderID, name }) => {
    setModalState({ status: MODAL.EDIT, id: folderID, name });

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.FOLDER_RENAME] },
    });
  };

  const rows = generateRows(folders, handleDeleteFolder, handleRenameFolder);

  const resetModal = () => setModalState({ status: MODAL.NONE, id: null });

  const deleteFolder = () => {
    dispatch(setFolderLoader(true));
    dispatch({
      type: "DELETE_FOLDER",
      folderID: modalState.id,
    });
    setModalState({ status: MODAL.NONE, id: null });
  };

  const transferOwnership = () => {
    navigate("/settings/participants" + location.search, {
      state: {
        highlight: true,
      },
    });
  };

  return (
    <>
      <ConfirmationModal
        title={modalState?.shouldShowTransferInfo ? "" : "Delete Folder"}
        show={modalState?.status === MODAL.DELETE}
        cancelText={
          modalState?.shouldShowTransferInfo ? "Delete Folder" : "Cancel"
        }
        confirmText={
          modalState?.shouldShowTransferInfo ? "Transfer Ownership" : "Confirm"
        }
        onCancel={
          modalState?.shouldShowTransferInfo
            ? () =>
                setModalState((p) => ({ ...p, shouldShowTransferInfo: false }))
            : resetModal
        }
        onConfirm={
          modalState?.shouldShowTransferInfo ? transferOwnership : deleteFolder
        }
        onExit={resetModal}
        className={modalState?.shouldShowTransferInfo ? "transfer-modal" : ""}
        cancelClass={modalState?.shouldShowTransferInfo ? "px-4 transfer" : ""}
        confirmClass={modalState?.shouldShowTransferInfo ? "px-4 transfer" : ""}
        footerClass="pt-0"
      >
        {modalState?.shouldShowTransferInfo ? (
          <>
            <img
              src="/assets/images/icons/transfer-ownership.jpg"
              alt="Transfer Ownership"
              className="w-100 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=07UlKexpI7Q",
                  "_blank"
                )
              }
            />

            <h2>
              Want to free up storage space?
              <br />
              Try the transfer ownership feature
            </h2>

            <p>
              Note: It will remove the photos from your storage.
              <br />
              Group members can still access/view the photos.
            </p>
          </>
        ) : (
          <>
            <p className="font-bold font-15">
              Are you sure you want to delete this folder?
            </p>
            <p className="text-danger font-14 px-5">
              <span className="font-bold font-16">&#x24D8;</span> You will not
              be able to re-upload the photos of this folder for next 24 hrs
            </p>
          </>
        )}
      </ConfirmationModal>
      <RenameFolderModal
        show={modalState?.status === MODAL.EDIT}
        handleHide={() => setModalState({})}
        id={modalState?.id}
        name={modalState?.name}
      />
      <Spinner loading={loader || initialLoader} />
      {rows.length === 0 ? (
        <h4>No Folders Found</h4>
      ) : (
        <CustomTable
          columns={columns}
          rows={rows}
          totalWidth={media ? window.innerWidth - 40 : 900}
        />
      )}
    </>
  );
};

export default FoldersTable;

function generateRows(folders, handleDeleteFolder, handleRenameFolder) {
  return (folders || []).map((folder) => {
    const { folderPrefix, allPicsCount, groupFolder } = folder || {};
    const isAPFolder = folderPrefix === "All Photos";

    return {
      name: withTooltip(<span>{folderPrefix || "_"}</span>, folderPrefix),

      count: allPicsCount + " Photos" || "0 Photos",
      edit: withTooltip(
        <span
          style={{ cursor: isAPFolder ? "not-allowed" : "pointer" }}
          onClick={() =>
            isAPFolder
              ? {}
              : handleRenameFolder({
                  folderID: groupFolder,
                  name: folderPrefix,
                })
          }
        >
          <img
            src={
              isAPFolder
                ? "/assets/images/icons/edit-greyed.png"
                : "/assets/images/icons/edit.png"
            }
            alt="Edit icon"
            width={20}
          />
        </span>,
        isAPFolder ? "You can't rename this folder" : "Rename"
      ),
      delete: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteFolder(groupFolder)}
        >
          <img
            src="/assets/images/icons/bin.png"
            alt="Delete icon"
            width={20}
          />
        </span>
      ),
    };
  });
}
