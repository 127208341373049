import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getCFURL, getImageName } from "utils/helpers";
import { GET_ALL_IMAGES_OF_GROUP } from "../../../../redux-store/sagas/saga-actions";

import { IMAGES_PER_PAGE } from "./FolderImages";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { modifyGallaryRoute } from "utils/helpers/routes";
import { allImagesType } from "views/routes/GalleryRoute";
import LightboxToolbar from "./LightboxToolbar";
import LightboxVideos from "./LightboxVideos";
import { VIDEOS_PER_PAGE } from "./FolderVideos";
import useHighResAlert from "hooks/useHighResAlert";
import { isIosDevice } from "hooks/useDetectUserAgent";
import HighResWarn from "views/components/modals/HighResWarn";
import { pushState } from "utils/helpers/debounced-history";

const DIFFERENCE = 4;

const GallaryModal = ({
  selectedID,
  folderId,
  closeImageView,
  fetchData,
  addToCart,
  removeFromCart,
}) => {
  const [params] = useSearchParams();
  const [initialImageId] = useState(selectedID || params.get("imageId"));
  const [initialVideoId] = useState(selectedID || params.get("videoId"));
  const [open, setOpen] = React.useState(true);
  const pageWhenOpened = React.useRef(Number(params.get("page")));
  const shouldRefetch = React.useRef(false);

  const {
    fullScreenImages: images,
    totalCount,
    allVideos,
  } = useSelector((state) => state?.folderImages);
  const page = Number(params.get("page"));

  const imageIndex = useMemo(() => {
    let idx = images?.findIndex((image) => {
      if (
        folderId === "my-photos" ||
        folderId === "purchased" ||
        folderId === "deleted"
      ) {
        return image?.imageId === initialImageId;
      } else {
        return image?._id === initialImageId;
      }
    });

    return idx >= 0 ? idx : 0;
  }, []);

  const videoIndex = useMemo(() => {
    let idx = 0;
    if (folderId === "videos") {
      idx = allVideos?.findIndex((video) => {
        return video?._id === initialVideoId;
      });
    }

    return idx >= 0 ? idx : 0;
  }, []);

  const [imgIndex, setImgIndex] = useState(imageIndex);
  const [vidIndex, setVideoIndex] = useState(videoIndex);
  const [imageSrc, setImageSrc] = useState({
    images: new Array(totalCount).fill({ src: "" }),
  });
  const [highResAlert, setHighResAlert] = useState(false);
  const { performCheck, setResult } = useHighResAlert(params.get("groupId"));
  const dispatch = useDispatch();

  useEffect(() => {
    let hasHighRes = false;

    let imgLink = images?.map((items) => {
      hasHighRes = items?.highRes || hasHighRes;

      return {
        src: getCFURL({ url: items?.url }),
        ...(folderId == "favorites"
          ? {
              description: `${items?.comment ? `"${items.comment}"` : ""}\n\n${
                getImageName(items?.imageName) || ""
              }`,
            }
          : {}),
      };
    });

    // not being used, keeping it for future reference
    // can be used to show small sized images for low res devices
    // let srcSet = images?.map((items) => {
    //   let data = { src: items?.url, width: 320, height: 213 };
    //   return data;
    // });

    if (performCheck && isIosDevice()) {
      setHighResAlert(hasHighRes);
      setResult(hasHighRes);
    }
    setImageSrc({ images: imgLink });
  }, [images]);

  useEffect(() => {
    let num = Math.ceil(imgIndex / 289);
    if (imgIndex >= 290 && images.length < 300 * Math.floor(imgIndex / 289)) {
      dispatch({
        type: GET_ALL_IMAGES_OF_GROUP,
        groupID: params.get("groupId"),
        page: num,
        limit: IMAGES_PER_PAGE,
        imageType: allImagesType.DATE,
      });
    }
  }, [imgIndex]);

  useEffect(() => {
    const image = images?.[imgIndex];
    let id = image?._id;
    if (
      folderId === "my-photos" ||
      folderId === "purchased" ||
      folderId === "deleted"
    ) {
      id = image?.imageId;
    }

    const newPage =
      Math.floor(
        folderId === "videos"
          ? imgIndex / VIDEOS_PER_PAGE
          : imgIndex / IMAGES_PER_PAGE
      ) + 1;

    if (newPage !== page) {
      pushState(
        null,
        "",
        modifyGallaryRoute(
          { imageId: id, pageNo: newPage, videoId: allVideos[imgIndex]?._id },
          params
        )
      );
    } else {
      // need to find an alternative without causing state updates,
      // as it is probably causing https://kwikpic.sentry.io/issues/3911136359/
      pushState(
        null,
        "",
        modifyGallaryRoute(
          { imageId: id, pageNo: newPage, videoId: allVideos[imgIndex]?._id },
          params
        )
      );
    }

    let _page;
    if (imgIndex + 1 - (page - 1) * IMAGES_PER_PAGE <= DIFFERENCE) {
      // load prev images
      _page = page - 1;
    } else if (page * IMAGES_PER_PAGE - imgIndex + 1 <= DIFFERENCE) {
      // load next images
      _page = page + 1;
    }
    if (typeof _page === "undefined") return;
    if (
      _page <= 0 ||
      _page - 1 >= Math.ceil(totalCount / IMAGES_PER_PAGE) ||
      _page === page
    ) {
      return;
    }

    const startIndex = (_page - 1) * IMAGES_PER_PAGE;
    const endIndex = startIndex + IMAGES_PER_PAGE - 1;

    const toBeFetchedImages = images?.slice(startIndex, endIndex);

    const shouldNotFetch = toBeFetchedImages?.every((img) => !!img);

    if (shouldNotFetch) return;
    fetchData(_page);
    if (Number(params.get("page")) !== pageWhenOpened.current)
      shouldRefetch.current = false;
    else shouldRefetch.current = true;
  }, [imgIndex, images]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return () => document.removeEventListener("contextmenu", handleContextmenu);
  }, []);

  return (
    <div className="lightbox">
      {folderId == "videos" ? (
        <LightboxVideos
          key={allVideos[vidIndex]?._id}
          params={params}
          page={page}
          open={open}
          folderId={folderId}
          setOpen={setOpen}
          closeImageView={closeImageView}
          vidIndex={vidIndex}
          setVideoIndex={setVideoIndex}
        />
      ) : (
        <Lightbox
          open={open}
          index={imgIndex}
          on={{
            view: ({ index }) => {
              setImgIndex(index);
            },
          }}
          close={() => {
            // add back below code if esc button close shows images from incorrect page
            // if (
            //   imgIndex % IMAGES_PER_PAGE <= DIFFERENCE ||
            //   imgIndex % IMAGES_PER_PAGE >= IMAGES_PER_PAGE - DIFFERENCE
            // )
            //   shouldRefetch.current = true;

            closeImageView(shouldRefetch.current);
          }}
          carousel={{
            preload: 2,
          }}
          slides={imageSrc.images}
          slideshow={{ autoplay: false, delay: 3000 }}
          plugins={[Zoom, Slideshow, Captions]}
          animation={{ zoom: 500 }}
          toolbar={{
            buttons: [
              <LightboxToolbar
                key={folderId}
                closeImageView={() => closeImageView(shouldRefetch.current)}
                imgIndex={imgIndex}
                totalCount={totalCount}
                images={images}
                fetchData={fetchData}
                addToCart={addToCart}
                removeFromCart={removeFromCart}
              />,
              // workaround for now, as rendering it outside the lightbox makes it unresponsive
              isIosDevice() && (
                <HighResWarn
                  key={Math.random()}
                  show={highResAlert}
                  onHide={() => setHighResAlert(false)}
                />
              ),
            ],
          }}
          zoom={{
            maxZoomPixelRatio: 1,
            zoomInMultiplier: 2,
            doubleTapDelay: 300,
            doubleClickDelay: 300,
            doubleClickMaxStops: 2,
            keyboardMoveDistance: 50,
            wheelZoomDistanceFactor: 100,
            pinchZoomDistanceFactor: 100,
            scrollToZoom: true,
          }}
        />
      )}
    </div>
  );
};
export default GallaryModal;
