/**
 * Todo: Extract all functions in a hook
 */

import React, { useEffect, useState, useRef } from "react";
import SettingPageHeading from "../components/SettingPageHeading";
import DefaultInput from "../../components/input/DefaultInput";
import SmallRoundedButton from "../../components/button/SmallRoundedButton";
import DefaultSwitch from "../../components/input/DefaultSwitch";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/loader/Spinner";
import {
  GET_BUSINESS_BRANDING,
  POST_BUSINESS_BRANDING,
  POST_BUSINESS_LOGO,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import { deepEqual, isValidPhoneNumber } from "utils/helpers";
import MediaIcons from "views/gallary/components/MediaIcons";
import SecondaryButton from "../../components/button/SecondaryButton";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import SubscriptionModal from "views/subscription";
import CountryJson from "views/auth/components/CountryJson";
import UserAgentErrorModal from "views/components/modals/UserAgentModal";
import { useSearchParams } from "react-router-dom";
import validateBusinessSettings from "./validator";
import { pick } from "lodash";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import Input from "views/components/input/Input";
import { toast } from "react-toastify";
import ANALYTICS from "constants/analyticsKeys";
import ConfirmationModal from "views/components/modals/ConfirmationModal";

const INIT = {
  business_name: " ",
  business_phone: " ",
  business_email: " ",
  whatsApp_link: " ",
  youtube_link: " ",
  vimeo_link: " ",
  website: " ",
  business_phone_d: false,
  business_phone_country_code: "+91",
  business_email_d: false,
  whatsApp_link_d: false,
  youtube_link_d: false,
  vimeo_link_d: false,
  website_d: false,
  fb_link: " ",
  fb_link_d: false,
  insta_link: " ",
  insta_link_d: false,
};

const BusinessSettings = () => {
  const [business, setBusiness] = useState(INIT);
  const [groupCover, setGroupCover] = useState(null);
  const initialValue = useRef({});
  const coverInputRef = useRef("null");
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [showPhoneUpdateModal, setShowPhoneUpdateModal] = useState(false);
  const [userAgentErrorModal, setUserAgentErrorModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});

  const dispatch = useDispatch();
  const { businessBranding, saveLoader, settingLoader, error } = useSelector(
    (state) => state.settings
  );
  const { country } = useSelector((state) => state.globals);

  const { hasAccessToFeature } = useSubscription();

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  usePreventUnsavedNavigation({
    message: "You have unsaved changes. Do you really want to leave this page?",
    block: !deepEqual(initialValue.current, business),
  });

  useEffect(() => {
    dispatch({
      type: GET_BUSINESS_BRANDING,
    });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      if (Array.isArray(error)) {
        const keys = {};
        error.forEach((e) => {
          const k = /"(.+)"/.exec(e);
          if (k) {
            keys[k[1]] = true;
          }
        });

        setInvalidFields(keys);
      } else if (typeof error === "object") {
        setInvalidFields((p) => ({
          ...p,
          ...Object.keys(error).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {}),
        }));
      }
    }
  }, [error]);

  const {
    business_name,
    business_phone,
    business_email,
    whatsApp_link,
    youtube_link,
    vimeo_link,
    website,
    business_phone_d,
    business_phone_country_code,
    business_email_d,
    whatsApp_link_d,
    youtube_link_d,
    vimeo_link_d,
    website_d,
    fb_link,
    fb_link_d,
    insta_link,
    insta_link_d,
  } = business;

  useEffect(() => {
    if (Object.keys(businessBranding).length === 0) return;
    const data = pick(businessBranding, Object.keys(INIT));
    setBusiness(data);
    initialValue.current = data;
  }, [businessBranding]);

  const hasFeatureAccess = () => {
    if (!hasAccessToFeature(PLAN_FEATURES.business_branding)) {
      setSubscriptionModal(true);
      return false;
    }
    return true;
  };

  const handleSave = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.BRANDING_SAVE] },
    });

    if (!hasFeatureAccess()) {
      return;
    }

    const user = getLocalStorageUser();

    const { value, invalidFields } = validateBusinessSettings(business);

    if (Object.keys(invalidFields).length > 0)
      return setInvalidFields(invalidFields);

    dispatch({
      type: POST_BUSINESS_BRANDING,
      payload: {
        ...value,
        userName: user?.name || "",
        userLastName: user?.lastName || "",
        ...(whatsApp_link && {
          whatsApp_link,
        }),
      },
    });
    setBusiness({ ...business, ...value });
    initialValue.current = { ...business, ...value };
  };

  /**
   * @param {Event & { target:HTMLInputElement }} e
   */
  const handleOnChange = async (e) => {
    if (e.target.files[0]) {
      const image = new Image();
      image.src = URL.createObjectURL(e.target.files[0]);

      const isOk = await new Promise((resolve) => {
        image.onload = () => {
          if (image.width > 12000 || image.height > 12000) {
            toast.error("Image dimension should not exceed 12000x12000");
            resolve(false);
          }
          resolve(true);
        };
        image.onerror = () => {
          toast.error("Image can't be read, please select another.");
          resolve(false);
        };
      });

      if (!isOk) return;

      setGroupCover(e.target.files[0]);
      dispatch({
        type: POST_BUSINESS_LOGO,
        payload: {
          groupCover: e.target.files[0],
        },
      });
    }
  };

  const setPhone = (code, phone) =>
    setBusiness({
      ...business,
      business_phone: phone || " ",
      business_phone_country_code: code,
    });

  const handleBrowse = (e) => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.BRANDING_LOGO_BROWSE] },
    });

    if (token) {
      return setUserAgentErrorModal(true);
    }

    if (!hasFeatureAccess()) return e?.preventDefault();
    coverInputRef.current.click();
  };

  return (
    <div>
      <Spinner loading={saveLoader || settingLoader} />

      <SettingPageHeading
        title="Business Branding"
        btnTitle="Save"
        onClick={handleSave}
        goBack={token && !noback ? () => history.back() : false}
      />
      <div className="row switchOnMobile">
        <div className="col-lg-5">
          <div className="mt-4">
            <h4>Business Name</h4>
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="Business Name"
                value={business_name === " " ? "" : business_name}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    business_name: false,
                  }));
                  setBusiness({ ...business, business_name: e.target.value });
                }}
                invalid={invalidFields.business_name}
              />
            </div>
          </div>
          <div className="mt-4">
            <h4>Business Phone Number</h4>
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="Business phone Number "
                value={business_phone === " " ? "" : business_phone}
                onClick={() => {
                  setInvalidFields((p) => ({
                    ...p,
                    business_phone: false,
                  }));
                  setShowPhoneUpdateModal(true);
                }}
                invalid={invalidFields.business_phone}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in gallery</p>
              <DefaultSwitch
                isChecked={business_phone_d}
                onChange={(e) =>
                  setBusiness({
                    ...business,
                    business_phone_d: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <h4>Business Email</h4>
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="Business Email"
                value={business_email === " " ? "" : business_email}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    business_email: false,
                  }));
                  setBusiness({
                    ...business,
                    business_email: e.target.value,
                  });
                }}
                invalid={invalidFields.business_email}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in gallery</p>
              <DefaultSwitch
                isChecked={business_email_d}
                onChange={(e) =>
                  setBusiness({
                    ...business,
                    business_email_d: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <h4>Website</h4>
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="https://weblink.com"
                value={website === " " ? "" : website}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    website: false,
                  }));
                  setBusiness({ ...business, website: e.target.value || " " });
                }}
                invalid={invalidFields.website}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in gallery</p>
              <DefaultSwitch
                isChecked={website_d}
                onChange={(e) =>
                  setBusiness({ ...business, website_d: e.target.checked })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <h4>Social Media Links</h4>
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="https://instagram.com/businessname"
                value={insta_link === " " ? "" : insta_link}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    insta_link: false,
                  }));
                  setBusiness({
                    ...business,
                    insta_link: e.target.value || " ",
                  });
                }}
                invalid={invalidFields.insta_link}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in gallery</p>
              <DefaultSwitch
                isChecked={insta_link_d}
                onChange={(e) =>
                  setBusiness({ ...business, insta_link_d: e.target.checked })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="https://facebook.com/businessname"
                value={fb_link === " " ? "" : fb_link}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    fb_link: false,
                  }));
                  setBusiness({ ...business, fb_link: e.target.value || " " });
                }}
                invalid={invalidFields.fb_link}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in gallery</p>
              <DefaultSwitch
                isChecked={fb_link_d}
                onChange={(e) =>
                  setBusiness({ ...business, fb_link_d: e.target.checked })
                }
              />
            </div>
          </div>
          <div className="dropdown-divider my-4" />
          <h4 className="d-flex align-items-center gap-2">
            <img src="/assets/images/icons/premium.png" className="hw-18px" />
            Only for Portfolio
          </h4>
          <div className="mt-4">
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="WhatsApp Phone Number"
                value={whatsApp_link === " " ? "" : whatsApp_link}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    whatsApp_link: false,
                  }));
                  setBusiness({
                    ...business,
                    whatsApp_link: e.target.value || " ",
                  });
                }}
                invalid={invalidFields.whatsApp_link}
              />
            </div>
            <p className="mt-2 mb-0">
              Country-code starting with + followed by phone number (without
              spaces or special characters)
            </p>
            <div className="dig mt-1">
              <p>Display in portfolio</p>
              <DefaultSwitch
                isChecked={whatsApp_link_d}
                onChange={(e) =>
                  setBusiness({
                    ...business,
                    whatsApp_link_d: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="https://youtube.com/@businessname"
                value={youtube_link === " " ? "" : youtube_link}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    youtube_link: false,
                  }));
                  setBusiness({
                    ...business,
                    youtube_link: e.target.value || " ",
                  });
                }}
                invalid={invalidFields.youtube_link}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in portfolio</p>
              <DefaultSwitch
                isChecked={youtube_link_d}
                onChange={(e) =>
                  setBusiness({ ...business, youtube_link_d: e.target.checked })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex">
              <Input
                className="border-1 font-bold color-primary"
                placeholder="https://vimeo.com/@businessname"
                value={vimeo_link === " " ? "" : vimeo_link}
                onChange={(e) => {
                  setInvalidFields((p) => ({
                    ...p,
                    vimeo_link: false,
                  }));
                  setBusiness({
                    ...business,
                    vimeo_link: e.target.value || " ",
                  });
                }}
                invalid={invalidFields.vimeo_link}
              />
            </div>
            <div className="dig mt-2">
              <p>Display in portfolio</p>
              <DefaultSwitch
                isChecked={vimeo_link_d}
                onChange={(e) =>
                  setBusiness({ ...business, vimeo_link_d: e.target.checked })
                }
              />
            </div>
          </div>
        </div>
        <div className="col-lg-5 ms-xl-4">
          <div className="mt-4">
            <h4>Logo</h4>
            <label
              style={{ display: !businessBranding?.logo ? "flex" : "none" }}
              className="dropableCard mx-0"
              htmlFor="file"
            >
              <input
                type="file"
                id="file"
                onClick={handleBrowse}
                className="d-none"
                ref={coverInputRef}
                onChange={handleOnChange}
                accept=".png, .jpg, .jpeg"
              />
              <span>Upload Business Logo</span>
              <div className="mt-4" />
              <SmallRoundedButton title="Browse" onClick={handleBrowse} />
              <div className="mt-4" />
              <p className="text-center">
                Formats supported are JPEG & PNG <br />
                Maximum size allowed is 5 mb
              </p>
            </label>
          </div>
          {businessBranding?.logo && (
            <div className="mt-4 " style={{ width: "100%" }}>
              <div className="focus-image-container-desktop-wrapper p-3">
                <div className="group-info-wrapper justify-content-center">
                  <div className="gallaryHeadingRight flex-row gallaryHeadingRight-branding p-0 w-full">
                    <div className="d-flex flex-column align-items-end align-self-center">
                      <div>
                        <h2 className="text-clip-line-2 text-left font-bold m-0 p-0">
                          Album by {business.business_name}
                        </h2>
                      </div>
                      <div className="mt-1">
                        <MediaIcons isEditing={true} businessData={business} />
                      </div>
                    </div>
                    <div className="companyLogoDivider-branding"></div>
                    <div className="companyLogo-branding">
                      <img
                        src={
                          groupCover
                            ? URL.createObjectURL(groupCover)
                            : businessBranding?.logo
                        }
                        alt="thumb"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SecondaryButton
                className="mt-3"
                title={"Edit"}
                onClick={handleBrowse}
              />
            </div>
          )}
          <div className="dropdown-divider d-xl-none mt-4" />
        </div>
      </div>

      {showPhoneUpdateModal && (
        <PhoneModal
          countryCode={business_phone_country_code || country?.dial_code}
          setPhone={setPhone}
          phone={business_phone}
          show={showPhoneUpdateModal}
          close={() => setShowPhoneUpdateModal(false)}
        />
      )}
      {subscriptionModal && (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        />
      )}
      {userAgentErrorModal && (
        <UserAgentErrorModal
          show={userAgentErrorModal}
          onHide={() => setUserAgentErrorModal(false)}
          // deviceType={deviceType}
          variant={1}
        />
      )}
    </div>
  );
};

export default BusinessSettings;

const PhoneModal = ({ countryCode, setPhone, phone, show, close }) => {
  const [phoneDetails, setPhoneDetails] = useState({
    cc: countryCode,
    phone,
  });
  const [error, setError] = useState("");

  const submit = () => {
    if (isValidPhoneNumber(phoneDetails.phone, phoneDetails.cc)) {
      setPhone(phoneDetails.cc, phoneDetails.phone);
      close();
    } else {
      setError("Phone enter a valid phone number.");
    }
  };

  return (
    <ConfirmationModal
      animation
      show={show}
      className="phoneModal"
      title="Enter Phone Number"
      onCancel={close}
      footer={false}
    >
      <div className="d-flex justify-content-center w-100 countryCode">
        <CountryJson
          setCountryCode={(e) => setPhoneDetails({ ...phoneDetails, cc: e })}
          countryCode={phoneDetails.cc}
        />
        <DefaultInput
          value={phoneDetails.phone}
          type="number"
          onChange={(e) =>
            setPhoneDetails((p) => ({ ...p, phone: e.target.value }))
          }
          onKeyDown={(e) => {
            // reject [.,-,e,+] keys
            if (["e", ".", "-", "+"].includes(e.key)) e.preventDefault();
          }}
          placeholder="Enter Phone Number"
          error={error}
          className="no-border"
        />
      </div>
      <SmallRoundedButton
        title="Continue"
        className="mt-3 mx-auto"
        onClick={submit}
      />
    </ConfirmationModal>
  );
};
