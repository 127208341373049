import { createSlice } from "@reduxjs/toolkit";
import { addUserToLocalStorage } from "utils/helpers/localstorage";
import { STATIC_FOLDERS } from "views/components/modals/UploadFromComputer";
import {
  deleteImg,
  deleteMultipleImages,
  updateImgFavStatus,
  updateMultipleFavorite,
} from "./folderImages";
import { setFolders } from "./folders";

const initialState = {
  generalSettings: {},
  privacySettings: {},
  participantsSettings: {},
  leaveParticipantSettings: {},
  foldersSettings: {},
  designSettings: {},
  downloadSettings: {},
  clientFavourites: {},
  settingLoader: false,
  saveLoader: false,
  businessBranding: {},
  leaveGroupStatus: {},
  userProfileDetails: {},
  updatePhoneOrEmailSendOTPResponse: {},
  updatePhoneOrEmailVerifyOTPResponse: {},
  renameFolderResponse: {},
  groupDetails: {},
  userAnalytics: {},
  groupSettingLoader: false,
  brandingData: {},
  wallet: {},
  credit: {},
  digitalAlbum: {},
  sponsors: [],
  photographer: {},
  productPrices: {},
  error: "",
  // designSettingsGroupId: "",
};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setGroupSettingsAndOtherSettings: (state, action) => {
      const { data, status } = action?.payload || {};
      if (status === 200) {
        const {
          name,
          _id,
          participants,
          canAnyoneUploadPhotos,
          isPrivate,
          // favoritePics,
          newJoineShowPreviousPhotos,
          icon,
          isAdmin,
          uploadParticipants,
          iconFocalPoint,
          coverIcon,
          sortStrategy,
          isDeletedFolderHidden,
          canAnyoneChangeNameIcon,
          anyOneJoinWithLink,
          folders,
          guestFolderOnly,
        } = data?.group || {};

        const { sponsors = [] } = data?.webSettings || {};

        let allowDownload = false;
        let originalDownload = false;
        if (data?.webSettings) {
          if (data.webSettings?.download)
            allowDownload = data.webSettings.download?.allowDownload;
          originalDownload = data.webSettings?.originalDownload;
        }

        //The Math.random is so that the url is always unique
        //as the backend always sends the same link for a photographer
        //the image doesnt update to the new one if cache is enabled and link is same as the old one
        //It shouldnt affect the image fetching, but if it does, remove it and make the backend send a unique url

        let brandingData = {};
        if (data?.brandingData) {
          brandingData = {
            ...data?.brandingData,
            // logo:
            //   data?.brandingData.logo +
            //   "?tag=" +
            //   Math.floor(Math.random() * 10000),

            //   the above setup is no more required as the backend now sends a unique url
          };
        }

        const generalSettings = {
          groupID: _id,
          groupName: name,
          icon,
          coverIcon,
          iconFocalPoint,
          allowDownload,
          originalDownload,
          sortStrategy,
        };

        const general_setting_loader = true;

        const participantsSettings = {
          groupID: _id,
          participants,
        };
        const leaveParticipantSettings = {
          groupID: _id,
          isAdmin,
        };

        const privacySettings = {
          groupID: _id,
          isPrivate,
          canAnyoneUploadPhotos,
          canAnyoneChangeNameIcon,
          uploadParticipants,
          newJoineShowPreviousPhotos,
          anyOneJoinWithLink,
          isDeletedFolderHidden,
          guestFolderOnly,
        };

        let highlightFolder;

        const newFolders = folders.filter((folder) => {
          if (folder.folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
            highlightFolder = folder;
            return false;
          }

          // ignore all photos folder
          if (!folder.folderPrefix) return false;

          return true;
        });

        if (highlightFolder) newFolders.unshift(highlightFolder);

        return {
          ...state,
          generalSettings,
          general_setting_loader,
          participantsSettings,
          privacySettings,
          settingLoader: false,
          groupSettingLoader: false,
          leaveParticipantSettings,
          groupDetails: {
            ...data?.group,
            folders: newFolders,
          },
          brandingData,
          sponsors,
        };
      }
    },

    setFoldersSettings: (state, action) => {
      return {
        ...state,
        foldersSettings: action.payload,
      };
    },

    setGroupDesignaAndSettings: (state, action) => {
      const { data, status } = action?.payload || {};

      if (status === 200) {
        const {
          design,
          download,
          groupId,
          showMyBranding,
          productPrices = {},
          sponsorAccess: { sponsorAccess } = {},
          designAccess: { templatesAccess } = {},
        } = data;

        const downSettings = {
          ...download,
          originalDownload: data?.originalDownload,
          bulkDownload: data?.bulkDownload,
        };

        const allProducts = Object.entries(productPrices);
        const transformedPrices = {};

        allProducts.forEach((product) => {
          const [key, value] = product;
          const prices = [];

          for (let i = 0; i < value.length - 1; i++) {
            prices.push({
              minQuantity: value[i][0],
              max: value[i + 1][0] - 1,
              credit: value[i][1],
              amount: value[i][2],
            });
          }

          const lastItem = value[value.length - 1];
          if (lastItem) {
            prices.push({
              minQuantity: lastItem[0],
              max: "MAX",
              credit: lastItem[1],
              amount: lastItem[2],
            });
          }

          transformedPrices[key] = {
            prices,
            currency: lastItem[3],
          };
        });

        return {
          ...state,
          designSettings: {
            ...design,
            showMyBranding,
            sponsorAccess,
            designAccess: templatesAccess,
          },
          groupId,
          downloadSettings: downSettings,
          privacySettings: {
            ...state.privacySettings,
            anonymousAccess: download?.anonymousAccess,
          },
          productPrices: transformedPrices,
          settingLoader: false,
        };
      }
    },

    setGroupParticipantsSettings: (state, action) => {
      const { participants } = state?.participantsSettings || {};
      const { data: { status } = {}, id } = action?.payload || {};
      const newParticipants = participants.map((participant) => {
        if (participant.participantId === id) {
          return { ...participant, isAdmin: !participant.isAdmin };
        }
        return participant;
      });

      if (status === 200 || status === 201) {
        return {
          ...state,
          saveLoader: false,
          participantsSettings: {
            ...state.participantsSettings,
            participants: newParticipants,
          },
        };
      }
    },

    handleGroupGeneralSettingsChange: (state, action) => {
      return {
        ...state,
        saveLoader: false,
        generalSettings: {
          ...state.generalSettings,
          icon: action.payload.data.group.icon,
          coverIcon: action.payload.data.group.coverIcon,
          iconFocalPoint: action.payload.data.group.iconFocalPoint,
          groupName: action.payload.data.group.name,
          sortStrategy: action.payload.data.group.sortStrategy,
        },
      };
    },
    setGroupPrivacySettings: (state, action) => {
      const {
        _id,
        canAnyoneUploadPhotos,
        isPrivate,
        newJoineShowPreviousPhotos,
        uploadParticipants,
        isDeletedFolderHidden,
        canAnyoneChangeNameIcon,
        anyOneJoinWithLink,
        guestFolderOnly,
      } = action.payload?.data?.group || {};

      const privacySettings = {
        groupID: _id,
        isPrivate,
        canAnyoneUploadPhotos,
        canAnyoneChangeNameIcon,
        uploadParticipants,
        newJoineShowPreviousPhotos,
        anyOneJoinWithLink,
        isDeletedFolderHidden,
        guestFolderOnly,
      };

      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ...action.payload?.data?.group,
        },
        privacySettings: {
          ...privacySettings,
          anonymousAccess: state.downloadSettings?.anonymousAccess,
        },
      };
    },
    setPostGroupDesignaAndSettings(state) {
      return {
        ...state,
        saveLoader: false,
      };
    },

    setBusinessBranding: (state, action) => {
      const { data, status } = action?.payload || {};
      if (status === 200) {
        const {
          business_name,
          business_email,
          business_email_d,
          business_phone,
          business_phone_d,
          business_phone_country_code,
          whatsApp_link,
          whatsApp_link_d,
          youtube_link,
          youtube_link_d,
          vimeo_link,
          vimeo_link_d,
          fb_link,
          fb_link_d,
          insta_link,
          insta_link_d,
          logo,
          user,
          website,
          website_d,
          watermark_position,
          watermark_percent,
          watermark,
          albumLogo,
          albumName,
          useAforP,
        } = data || {};

        const businessBranding = {
          business_name: business_name || " ",
          business_email: business_email || " ",
          business_phone: business_phone || " ",
          business_phone_d,
          business_phone_country_code,
          business_email_d,
          website: website || " ",
          website_d,
          insta_link: insta_link || " ",
          insta_link_d,
          fb_link: fb_link || " ",
          fb_link_d,
          watermark_position,
          watermark_percent,
          watermark,
          user,
          logo,
          whatsApp_link: whatsApp_link || " ",
          whatsApp_link_d,
          youtube_link: youtube_link || " ",
          youtube_link_d,
          vimeo_link: vimeo_link || " ",
          vimeo_link_d,
          albumLogo,
          albumName,
          useAforP,
        };

        return {
          ...state,
          businessBranding,
        };
      }
    },

    setPutBusinessLogo: (state, action) => {
      return {
        ...state,
        businessBranding: {
          ...state.businessBranding,
          logo: action.payload.data.url,
        },
      };
    },

    setPutBusinessWatermark: (state, action) => {
      return {
        ...state,
        businessBranding: {
          ...state.businessBranding,
          watermark: action.payload.data.url,
        },
        saveLoader: false,
      };
    },

    removeWatermark: (state) => {
      return {
        ...state,
        businessBranding: {
          ...state.businessBranding,
          watermark: null,
        },
      };
    },

    setSettingLoader(state, action) {
      return {
        ...state,
        settingLoader: action.payload,
      };
    },

    setSaveLoader(state, action) {
      return {
        ...state,
        saveLoader: action.payload,
      };
    },

    clientFavourites: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        clientFavourites: {
          groupId: payload.groupID,
          favourites: payload.data.data.results,
        },
      };
    },

    setLeaveGroupStatus(state, action) {
      return {
        ...state,
        saveLoader: false,
        leaveGroupStatus: action.payload,
      };
    },
    setUserProfileData: (state, action) => {
      const { data: { user, photographer } = {}, status } =
        action?.payload || {};
      if (status === 200) {
        // lsProxy.setItem("user", JSON.stringify(user));
        addUserToLocalStorage(user);
        return {
          ...state,
          userProfileDetails: user,
          ...(photographer && { photographer }),
        };
      } else {
        return {
          ...state,
        };
      }
    },

    setSendOtpToUpdatePhoneOrEmail: (state, action) => {
      const { message, status } = action?.payload || {};

      if (status === 201 || status === 200) {
        return {
          ...state,
          updatePhoneOrEmailSendOTPResponse: { status, message },
          settingLoader: false,
        };
      }
    },

    clearUpdatePhoneOrEmailSendOTPResponse: (state) => {
      return {
        ...state,
        updatePhoneOrEmailSendOTPResponse: {},
      };
    },

    setVerifyOtpToUpdatePhoneOrEmail: (state, action) => {
      const { status } = action?.payload || {};
      if (status === 200) {
        return {
          ...state,
          updatePhoneOrEmailVerifyOTPResponse: { status },
          settingLoader: false,
        };
      }
      return {
        ...state,
        settingLoader: false,
      };
    },

    setDeleteGroupIcon: (state) => {
      return {
        ...state,
        generalSettings: { ...state.generalSettings, icon: null },
      };
    },
    removeParticipantFromGroup: (state, action) => {
      const { participantId, groupId, status } = action?.payload || {};
      const updatedParticipants =
        state?.participantsSettings?.participants.filter(
          (participant) => participant.participantId !== participantId
        );

      if (status === 200) {
        return {
          ...state,
          saveLoader: false,
          participantsSettings: {
            groupId,
            participants: updatedParticipants,
          },
        };
      }
      return {
        ...state,
        saveLoader: false,
      };
    },
    setUserAnalytics: (state, action) => {
      const { data, status } = action?.payload || {};

      if (status === 200) {
        return {
          ...state,
          userAnalytics:
            {
              ...data.userAnalytics,
              uploadLimit: data?.uploadLimit,
              totalUtilisation: data?.totalUtilisation,
              uploadVideoLimit: data?.uploadVideoLimit,
              maxPhotoUpload: data?.maxPhotoUpload,
            } || {},
        };
      }
      return {
        ...state,
      };
    },
    setGroupSettingsLoader: (state, action) => {
      return {
        ...state,
        groupSettingLoader: action.payload,
      };
    },
    setGroupAdminToken: (state, action) => {
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ...action.payload,
        },
      };
    },
    setBranchUrl: (state, action) => {
      const url = action.payload.url + "?groupCode=" + action.payload.groupCode;

      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          branchURL: url,
        },
      };
    },
    setUserWallet: (state, action) => {
      return {
        ...state,
        wallet: action.payload,
      };
    },
    setCreditsAddResponse: (state, action) => {
      return {
        ...state,
        credit: action.payload?.orderData,
      };
    },
    clearCreditsResponse: (state) => {
      return {
        ...state,
        credit: {},
      };
    },
    setDigitalAlbum: (state, action) => {
      return {
        ...state,
        digitalAlbum: {
          ...state.digitalAlbum,
          ...action.payload?.digitalAlbum,
        },
      };
    },
    updateParticipant: (state, action) => {
      const { participant } = action.payload;
      const { participants } = state.participantsSettings;
      const pIndex = participants.findIndex(
        (p) => p.participantId === participant.participantId
      );
      participants[pIndex] = {
        ...participants[pIndex],
        ...participant,
      };
    },
    setSponsors: (state, action) => {
      const { sponsors = [] } = action.payload?.data?.groupWebSettings || {};
      return {
        ...state,
        sponsors,
      };
    },
    setError: (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    clearGroupDetails: (state) => {
      state.generalSettings = {};
      state.participantsSettings = {};
      state.privacySettings = {};
      state.leaveParticipantSettings = {};
      state.groupDetails = {};
      state.brandingData = {};
      state.sponsors = [];
      state.designSettings = {};
      state.downloadSettings = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteMultipleImages, (state, action) => {
      const { ids, folderId } = action.payload;

      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ...(folderId === "deleted"
            ? {
                allPicsCount: state.groupDetails.allPicsCount + ids.length,
                deletePicsCount:
                  state.groupDetails.deletePicsCount - ids.length,
              }
            : {
                allPicsCount: state.groupDetails.allPicsCount - ids.length,
                deletePicsCount:
                  state.groupDetails.deletePicsCount + ids.length,
              }),
          ...(folderId === "my-photos" && {
            myPhotosCount: state.groupDetails.myPhotosCount - ids.length,
          }),
        },
      };
    });

    builder.addCase(setFolders, (state, action) => {
      const { data } = action?.payload || {};
      const { status, folders = [] } = data || {};
      let _folders = [];

      let highlightFolder;

      _folders = folders.filter((folder) => {
        if (folder.folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
          highlightFolder = folder;
          return false;
        }
        if (!folder.folderPrefix) return false;

        return true;
      });

      if (highlightFolder) {
        _folders.unshift(highlightFolder);
      }

      if (status === 200) {
        return {
          ...state,
          groupDetails: {
            ...state.groupDetails,
            folders: _folders,
          },
        };
      }
    });

    builder.addCase(deleteImg, (state, action) => {
      if (action.payload.src === "full-screen-restore") {
        return {
          ...state,
          groupDetails: {
            ...state.groupDetails,
            allPicsCount: state.groupDetails.allPicsCount + 1,
            deletePicsCount: state.groupDetails.deletePicsCount - 1,
          },
        };
      } else {
        return {
          ...state,
          groupDetails: {
            ...state.groupDetails,
            allPicsCount: state.groupDetails.allPicsCount - 1,
            deletePicsCount: state.groupDetails.deletePicsCount + 1,
          },
        };
      }
    });

    builder.addCase(updateImgFavStatus, (state, action) => {
      const { isFavorite, folderId } = action.payload;

      // update count only if the folder is not favorites
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ...(folderId !== "favorites" && {
            favoritePicsCount: isFavorite
              ? state.groupDetails.favoritePicsCount - 1
              : state.groupDetails.favoritePicsCount + 1,
          }),
        },
      };
    });

    builder.addCase(updateMultipleFavorite, (state, action) => {
      const {
        favouriteResults: { addedFav, removedFav },
        folderId,
      } = action.payload;
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ...(folderId !== "favorites" && {
            favoritePicsCount:
              state.groupDetails.favoritePicsCount +
              addedFav.length -
              removedFav.length,
          }),
        },
      };
    });
  },
});

export const {
  setGroupSettingsAndOtherSettings,
  setFoldersSettings,
  setGroupDesignaAndSettings,
  setGroupParticipantsSettings,
  handleGroupGeneralSettingsChange,
  setGroupPrivacySettings,
  setDesignaGroupSettings,
  setSaveLoader,
  setSettingLoader,
  setPostGroupDesignaAndSettings,
  setBusinessBranding,
  setPutBusinessLogo,
  setLeaveGroupStatus,
  setUserProfileData,
  setSendOtpToUpdatePhoneOrEmail,
  clearUpdatePhoneOrEmailSendOTPResponse,
  setVerifyOtpToUpdatePhoneOrEmail,
  setDeleteGroupIcon,
  removeParticipantFromGroup,
  clientFavourites,
  setUserAnalytics,
  setGroupSettingsLoader,
  setGroupAdminToken,
  setPutBusinessWatermark,
  removeWatermark,
  setBranchUrl,
  setUserWallet,
  setCreditsAddResponse,
  clearCreditsResponse,
  setDigitalAlbum,
  updateParticipant,
  setSponsors,
  setError,
  clearGroupDetails,
} = settings.actions;

export default settings.reducer;
