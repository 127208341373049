import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * @param {React.ReactNode} children
 * @param {String} title
 * @param {Boolean} [shouldShow=true]
 * @param {("top"|"bottom"|"left"|"right")} [placement="bottom"]
 * @returns {React.ReactNode}
 */
const withTooltip = (
  children,
  title,
  shouldShow = true,
  placement = "bottom"
) => {
  return shouldShow ? (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip style={{ position: "fixed" }}>{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
};

export default withTooltip;
