import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  purchasedItems: [],
  totalQuantity: 0,
  cartLoader: false,
  paymentSuccess: false,
  mappedCartItems: {}, // a map of pdt_id to _id
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartLoader(state, action) {
      state.cartLoader = action.payload;
    },
    setCart: (state, action) => {
      const cartItems = action.payload.cartItems;

      state.cartItems = cartItems;
      state.mappedCartItems = cartItems.reduce((acc, item) => {
        acc[item.pdt_id] = item._id;
        return acc;
      }, {});
    },
    addItemToCart: (state, action) => {
      const item = action.payload.addedCartItems;

      state.cartItems = [...state.cartItems, ...item];
      state.mappedCartItems = {
        ...state.mappedCartItems,
        ...item.reduce((acc, item) => {
          acc[item.pdt_id] = item._id;
          return acc;
        }, {}),
      };
    },
    removeAllFromCart: (state) => {
      state.cartItems = [];
      state.mappedCartItems = {};
    },
    removeItemFromCart: (state, action) => {
      const items = action.payload;

      state.cartItems = state.cartItems.filter(
        (cartItem) => !items.find((item) => item.pdt_id === cartItem.pdt_id)
      );
      state.mappedCartItems = {
        ...state.mappedCartItems,
        ...items.reduce((acc, item) => {
          acc[item.pdt_id] = null;
          return acc;
        }, {}),
      };
    },
    setPurchasedItems: (state, action) => {
      state.purchasedItems = action.payload;
    },
    setPurchaseSuccess: (state, action) => {
      state.cartItems = [];
      state.paymentSuccess = true;
      state.purchasedItems = action.payload;
      state.mappedCartItems = {};
    },
    resetCart: (state) => {
      state.cartItems = [];
      state.purchasedItems = [];
      state.totalQuantity = 0;
      state.cartLoader = false;
      state.paymentSuccess = false;
      state.mappedCartItems = {};
    },
  },
});

export const {
  setCartLoader,
  setCart,
  addItemToCart,
  removeAllFromCart,
  removeItemFromCart,
  setPurchasedItems,
  setPurchaseSuccess,
  resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
