import React from "react";
import { AXIOS, AXIOS2 } from "../utils/setup/axios";
import { useSearchParams } from "react-router-dom";
import usePreventUnsavedNavigation from "./usePreventUnsavedNavigation";
import useUploadStatus from "./useUploadingStatus";
import { toast } from "react-toastify";
import { NETWORK_ERROR } from "./useUploadFromComputer";
import { lsProxy } from "utils/helpers/localstorage";

export const VIDEO_SIZE_LIMIT = 104857600; //100MB
// const CHUNK_SIZE = 1024 * 1024 * 2;

const useUploadVideo = ({ handleClose, show }) => {
  const [params] = useSearchParams();
  const [videosToUpload, setVideosToUpload] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [hasInternetConnection, setHasInternetConnection] =
    React.useState(false);
  const { isParallelyUploading } = useUploadStatus();

  usePreventUnsavedNavigation({
    message: "Uploading is still in process. Are you sure you want to leave?",
    block: isUploading,
  });

  React.useEffect(() => {
    if (isUploading) {
      lsProxy.setItem("uploadingFrom", "computer");
      lsProxy.setItem("isUploading", true);
    } else {
      lsProxy.removeItem("isUploading");
      lsProxy.removeItem("uploadingFrom");
    }
  }, [isUploading]);

  React.useEffect(() => {
    if (!show) {
      setVideosToUpload([]);
      setIsUploading(false);
      setLoading(false);
      setConfirmationModal({});
    }
  }, [show]);

  React.useEffect(() => {
    if (hasInternetConnection) {
      const relevantImages = videosToUpload?.filter(
        (video) =>
          video.status !== videoStatus.DUPLICATE &&
          video.status !== videoStatus.SUCCESS &&
          video.status !== videoStatus.ERROR
      );

      toast.success("Uploading has been resumed.");

      if (relevantImages.length > 0) {
        lsProxy.removeItem("isUploading");
        startUpload();
      } else {
        setIsUploading(false);
      }
    }
  }, [hasInternetConnection]);

  const totalSize = videosToUpload.reduce((acc, curr) => {
    return acc + curr.size;
  }, 0);

  const successfullyUploaded = React.useMemo(() => {
    return videosToUpload?.filter(
      (image) => image.status === videoStatus.SUCCESS
    );
  }, [videosToUpload]);

  const videosWithError = React.useMemo(() => {
    return videosToUpload?.filter(
      (image) => image.status === videoStatus.ERROR
    );
  }, [videosToUpload]);

  const duplicateVideos = React.useMemo(() => {
    return videosToUpload?.filter(
      (image) => image.status === videoStatus.DUPLICATE
    );
  }, [videosToUpload]);

  const limitExceededVideos = React.useMemo(() => {
    return videosToUpload?.filter(
      (image) => image.status === videoStatus.LIMIT_EXCEEDED
    );
  }, [videosToUpload]);

  const successFullySentToBackend =
    successfullyUploaded.length +
    videosWithError.length +
    duplicateVideos.length +
    limitExceededVideos.length;

  const hasUploadedAllVideos =
    successFullySentToBackend === videosToUpload?.length &&
    successFullySentToBackend !== 0;

  const handleUploadVideo = async (videos) => {
    setLoading(true);
    const _videos = transformVideos(videos);
    setVideosToUpload(_videos);
    setLoading(false);
  };

  const createRequest = async (groupId, name, size) => {
    try {
      const res = await AXIOS.get(
        `/api/app/video/create-upload-request/${groupId}?fileName=${encodeURIComponent(
          name
        )}&fileSize=${size}`
      );
      return res;
    } catch (e) {
      if (e.response.status === 409) {
        setVideosToUpload((v) =>
          v.map((video) =>
            video.name === name
              ? { ...video, status: videoStatus.DUPLICATE }
              : video
          )
        );
        return { status: videoStatus.DUPLICATE };
      } else if (
        e.response.status === 400 ||
        e.response.status === 406 ||
        e.response.status === 407
      ) {
        setVideosToUpload((v) =>
          v.map((video) =>
            video.name === name
              ? { ...video, status: videoStatus.ERROR }
              : video
          )
        );
        toast.error(
          e.response?.data?.message || "Maximum file size (100 MB) exceeded."
        );
        return { status: videoStatus.ERROR };
      }
    }
  };

  const uploadProgress = (name, percentCompleted) => {
    setVideosToUpload((prev) =>
      prev.map((video) => {
        if (video.name === name) {
          return { ...video, uploadProgress: percentCompleted };
        }
        return video;
      })
    );
  };

  const startUpload = async (retry, isPublic) => {
    setIsUploading(true);

    const isAlreadyuploading = await isParallelyUploading();

    if (isAlreadyuploading) {
      toast.error(
        "One upload is already in progress. Please wait for that to finish"
      );
      setIsUploading(false);
      return;
    }

    const groupId = params.get("groupId");

    lsProxy.setItem("isUploading", true);

    const allVideos = retry || videosToUpload;

    let relevantVideos = allVideos.filter(
      (video) => video.status === videoStatus.READY
    );

    while (relevantVideos.length > 0) {
      const { name, size, file } = relevantVideos.shift();
      const res = await createRequest(groupId, name, size);
      const { fileId, video_name } = res.data?.data || {};

      if (res.status === 200) {
        const response = await uploadVideo(
          name,
          size,
          file,
          groupId,
          fileId,
          video_name,
          isPublic,
          relevantVideos.length === 0
        );

        if (response === videoStatus.SUCCESS) {
          setVideosToUpload((prev) =>
            prev.map((video) => {
              if (video.name === name) {
                return { ...video, status: videoStatus.SUCCESS };
              }
              return video;
            })
          );
        } else if (response === videoStatus.ERROR) {
          setVideosToUpload((prev) =>
            prev.map((video) => {
              if (video.name === name) {
                return { ...video, status: videoStatus.ERROR };
              }
              return video;
            })
          );
        } else if (response === NETWORK_ERROR) {
          try {
            await AXIOS.get(process.env.REACT_APP_API_URL);
          } catch (err) {
            if (err?.code === NETWORK_ERROR) {
              const _interval = setInterval(() => {
                AXIOS.get(process.env.REACT_APP_API_URL).then(() => {
                  setHasInternetConnection(true);
                  clearInterval(_interval);
                });
              }, 3000);
              setHasInternetConnection(false);
            }
          }
        }
      }
    }

    setIsUploading(false);
    lsProxy.setItem("isUploading", false);
  };

  // const createChunks = (file, size) => {
  //   const chunks = [];
  //   let start = 0;
  //   while (start < file.size) {
  //     const end = Math.min(start + size, file.size);
  //     chunks.push({
  //       file: file.slice(start, end),
  //       start,
  //       end,
  //     });
  //     start += size;
  //   }
  //   return chunks;
  // };

  const uploadVideo = async (
    name,
    size,
    file,
    groupId,
    fileId,
    video_name,
    isPublic,
    isLast,
    count = 1
  ) => {
    try {
      // const chunks = createChunks(file, CHUNK_SIZE);
      // let chunksUploaded = 0;
      // let sizeUploaded = 0;

      // for (let i = 0; i < chunks.length; i++) {

      // const chunk = chunks[i];
      const formData = new FormData();
      formData.append("videoChunk", file);
      formData.append("groupId", groupId);

      if (isLast) formData.append("videoUploadDone", true);

      const headers = {
        // "content-range": `bytes=${chunk.start}-${chunk.end}/${size}`,
        "content-range": `bytes=0-${size}/${size}`,
        "x-file-id": fileId,
        "x-video-name": video_name,
        "x-video-size": size,
        "x-group-id": groupId,
        "x-is-video-private": !isPublic,
      };

      const response = await AXIOS2.post(
        "/api/app/video/upload-video",
        formData,
        {
          headers,
          onUploadProgress: (progressEvent) => {
            // might be very costly, need to find a better way

            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            lsProxy.setItem("isUploading", true);
            lsProxy.setItem("uploadingFrom", "computer");

            uploadProgress(name, percentCompleted);
          },
        }
      );

      // if (response.status === 200 || response.status === 201) {
      //   ++chunksUploaded;
      //   sizeUploaded += chunk.end - chunk.start;
      // }

      if (response.status === 200 || response.status === 201) {
        return videoStatus.SUCCESS;
      }
      // }
    } catch (err) {
      if (err?.code === NETWORK_ERROR) {
        if (count < 3) {
          return await uploadVideo(
            name,
            size,
            file,
            groupId,
            fileId,
            video_name,
            isPublic,
            isLast,
            ++count
          );
        } else {
          return NETWORK_ERROR;
        }
      } else if (err?.response?.data?.status === 409) {
        return videoStatus.DUPLICATE;
      } else {
        return videoStatus.ERROR;
      }
    }
  };

  const handleFailedUploads = async () => {
    setVideosToUpload((prev) => {
      const _prev = prev
        .filter((video) => video.status === videoStatus.ERROR)
        .map((video) => {
          return {
            ...video,
            status: videoStatus.READY,
            uploadProgress: 0,
          };
        });
      startUpload(_prev);
      return _prev;
    });
  };

  const handleConfirmationModal = () => {
    if (isUploading) {
      setConfirmationModal({
        show: true,
        title: "Uploading in progress",
        message: "Are you sure you want to cancel the upload?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setIsUploading(false);
          handleClose();
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else if (!hasUploadedAllVideos && videosToUpload?.length > 0) {
      setConfirmationModal({
        show: true,
        title: "Selected Videos",
        message:
          "You have not uploaded the selected videos. Are you sure you want to cancel the process?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setConfirmationModal({});
          handleClose();
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else {
      setConfirmationModal({});
      handleClose();
    }
  };

  return {
    videosToUpload,
    handleUploadVideo,
    totalSize,
    startUpload,
    isUploading,
    hasUploadedAllVideos,
    confirmationModal,
    handleConfirmationModal,
    handleFailedUploads,
    loading,
    successFullySentToBackend,
    successfullyUploaded,
    duplicateVideos,
    videosWithError,
    limitExceededVideos,
  };
};

export default useUploadVideo;

const transformVideos = (images) => {
  return images.map((image) => {
    let ret = {
      name: image.name,
      size: image.size,
      file: image,
      type: image.type,
    };

    if (image.size < VIDEO_SIZE_LIMIT) {
      return {
        ...ret,
        status: videoStatus.READY,
      };
    } else {
      return {
        ...ret,
        status: videoStatus.LIMIT_EXCEED,
      };
    }
  });
};

export const videoStatus = {
  SUCCESS: "SUCCESS",
  READY: "UPLOAD",
  DUPLICATE: "DUPLICATE",
  ERROR: "ERROR",
  LIMIT_EXCEED: "ERROR: SIZE>100MB",
};
