/**
 * ToDo: Make a separate common component for gallary buttons (settings, share, download)
 */

import useDetectUserAgent from "hooks/useDetectUserAgent";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  SEND_USER_CLICK_ANALYTICS,
  GET_GROUP_ADMIN_TOKEN,
} from "redux-store/sagas/saga-actions";
import UserAgentErrorModal from "views/components/modals/UserAgentModal";
import GroupShareModal from "views/components/modals/GroupShareModal";
import { handleDownloadRouter } from "utils/helpers/downloads";
import Sponsors from "./Sponsors";
import ANALYTICS from "constants/analyticsKeys";
import ExpandableButton from "views/components/button/ExpandableButton";

const GallaryStyle4 = (props) => {
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  useDetectUserAgent(setDeviceType);

  const {
    leaveParticipantSettings: { isAdmin },
    downloadSettings: { allowDownload, bulkDownload },
    groupDetails = {},
  } = useSelector((state) => state.settings);

  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);

  const { pathname } = useLocation();
  const groupID = params.get("groupId");

  const handleSetModal = () => {
    if (groupDetails) {
      setModalShow(true);
      if (isAdmin && !groupDetails?.isPrivate) {
        dispatch({
          type: GET_GROUP_ADMIN_TOKEN,
          groupCode: groupDetails.groupCode,
        });
      }
    }
  };

  return (
    <div className="gallaryHeading GallaryStyle4">
      <Sponsors
        branding={props?.branding}
        isAnonymous={props?.isAnonymous}
        showPortfolio={props?.showPortfolio}
        template="2"
      />
      <div className="gallaryHeadingLeft d-flex flex-column align-items-center justify-content-center h-100">
        <h2 className="no-margin">{props.title}</h2>
        <span className="base-font font-12">
          {props?.group?.allPicsCount || 0} Photos
        </span>
        <div className="p-1 d-flex gap-2">
          <ExpandableButton
            className="GalleryHeadingLeftSetting d-flex d-md-none"
            icon="/assets/images/icons/setting.png"
            title="Settings"
            to={`/settings/list?groupId=${groupID}`}
            onClick={() => {
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.SETTINGS] },
              });
            }}
          />
          <ExpandableButton
            className="GalleryHeadingLeftSetting d-none d-md-flex"
            icon="/assets/images/icons/setting.png"
            title="Settings"
            to={`/settings/general?groupId=${groupID}`}
            onClick={() => {
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.SETTINGS] },
              });
            }}
          />
          <ExpandableButton
            className="GalleryHeadingLeftShare cursor-pointer"
            icon="/assets/images/icons/share.png"
            title="Share"
            onClick={() => {
              handleSetModal();
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.SHARE] },
              });
            }}
          />
          <ExpandableButton
            className="GalleryHeadingLeftDownload cursor-pointer"
            icon="/assets/images/icons/download.png"
            title="Download&nbsp;All"
            onClick={() => {
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.DOWNLOAD] },
              });
              handleDownloadRouter(
                groupID,
                navigate,
                deviceType,
                setShowErrorModal,
                { isAdmin, allowDownload, bulkDownload }
              );
            }}
          />
          {groupDetails.folders?.length > 10 && (
            <ExpandableButton
              className="GalleryHeadingLeftFolders"
              titleClassName="text-nowrap"
              {...(pathname === "/gallery/folders"
                ? {
                    icon: "/assets/images/icons/gallery/image.png",
                    to: `/gallery?groupId=${groupID}`,
                    title: "Image View",
                  }
                : {
                    icon: "/assets/images/icons/folder2.png",
                    to: `/gallery/folders?groupId=${groupID}`,
                    title: "Folder view",
                  })}
            />
          )}
        </div>
      </div>
      <div
        className="view-gallery-temp4 cursor-pointer"
        onClick={() =>
          document
            .getElementById("gallery-folders-list")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <img
          src="/assets/images/icons/triple-down-arrow.svg"
          alt="triple-down-arrow"
        />
        <p className="no-margin font-bold font-12 mt-2"> View Gallery </p>
      </div>
      {modalShow && (
        <GroupShareModal
          show={modalShow}
          groupDetails={groupDetails}
          onHide={() => setModalShow(false)}
          branding={props?.branding}
          isAdmin={isAdmin}
          isPrivate={groupDetails?.isPrivate}
        />
      )}
      <UserAgentErrorModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        deviceType={deviceType}
        variant={1}
      />
    </div>
  );
};
export default GallaryStyle4;
