import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "./Table";
import cx from "classnames";
import {
  setSaveLoader,
  // setSettingLoader,
} from "../../../redux-store/slices/settings";
import Spinner from "../../components/loader/Spinner";
import { useSearchParams } from "react-router-dom";
import {
  CREATE_TRANSFER_REQUEST,
  PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
  // GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  REMOVE_PARTICIPANT,
  SEND_USER_CLICK_ANALYTICS,
  UPDATE_GROUP_ACCESS,
} from "../../../redux-store/sagas/saga-actions";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import MutedButton from "../button/MutedButton";
import SecondaryButton from "../button/SmallRoundedButton";
import css from "../../settings/css/Participants.module.css";
import ANALYTICS from "constants/analyticsKeys";

const totalWidth = 1000;

const ParticipantsTable = ({
  admin,
  analyticsEnabled,
  highlight,
  search = "",
}) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [media, setMedia] = useState(
    window.matchMedia("(max-width: 410px)").matches
  );

  useEffect(() => {
    window.matchMedia("(max-width: 410px)").addEventListener("change", (e) => {
      setMedia(e.matches);
    });
  }, []);

  const columns = analyticsEnabled
    ? [
        {
          dataKey: "name",
          label: "Name",
          width: media ? window.innerWidth - 120 : 250,
        },
        {
          dataKey: "email",
          label: "Email",
          width: 250,
        },
        {
          dataKey: "phone",
          label: "Phone",
          width: 250,
        },
        {
          dataKey: "role",
          label: "Role",
          width: media ? 120 : 250,
        },
      ]
    : [
        {
          dataKey: "name",
          label: "Name",
          width: media ? window.innerWidth - 120 : 250,
        },
        {
          dataKey: "role",
          label: "Role",
          width: media ? 120 : 250,
        },
      ];

  // useEffect(() => {
  //   dispatch(setSettingLoader(true));
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: params.get("groupId"),
  //   });
  // }, []);

  const {
    participantsSettings,
    saveLoader,
    settingLoader,
    groupDetails: { createdBy, isPrivate },
  } = useSelector((state) => state.settings);

  const [settings, setSettings] = React.useState(participantsSettings);
  const [confirmationModal, setConfirmationModal] = React.useState({
    show: false,
    participant: null,
  });

  useEffect(() => {
    if (Object.keys(participantsSettings).length > 0) {
      setSettings(participantsSettings);
    }
  }, [participantsSettings]);

  const { participants, groupID } = settings;

  const user = getLocalStorageUser();
  const isCreator = user?._id === createdBy;

  const changeAccessLevel = (participantId, allPicsAccess) => {
    dispatch({
      type: UPDATE_GROUP_ACCESS,
      payload: {
        id: params.get("groupId"),
        participantId,
        allPicsAccess,
      },
    });
  };

  const handleChange = (eventKey, participantID) => {
    if (eventKey === "RemoveParticipant") {
      dispatch({
        type: REMOVE_PARTICIPANT,
        payload: {
          groupID: params.get("groupId"),
          participantID,
        },
      });

      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.PARTICIPANT_REMOVE] },
      });
      return;
    }

    const participant = participants.find(
      (participant) => participant.participantId === participantID
    );

    if (eventKey === "TransferOwnership") {
      setConfirmationModal({
        show: true,
        participant,
      });

      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.PARTICIPANT_TRANSFER] },
      });
      return;
    }

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.PARTICIPANT_CHANGE_ROLE] },
    });

    if (eventKey === "Viewer - Partial Access")
      return changeAccessLevel(participantID, false);
    if (eventKey === "Viewer - Full Access")
      return changeAccessLevel(participantID, true);

    const changeToAdmin = eventKey === "Admin";
    if (participant.isAdmin === changeToAdmin) return;
    else {
      dispatch(setSaveLoader(true));
      dispatch({
        type: PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
        payload: {
          isAdmin: changeToAdmin,
          participantID,
          groupID,
        },
      });
    }
  };

  const confirmOwnershipChange = () => {
    dispatch({
      type: CREATE_TRANSFER_REQUEST,
      payload: {
        groupId: params.get("groupId"),
        receiverId: confirmationModal?.participant?.participantId,
      },
    });

    setConfirmationModal({
      show: false,
      participant: null,
    });
  };

  const updatedParticipants = useMemo(() => {
    return participants
      ?.map((participant) => {
        if (participant?.participantId === user?._id) {
          return {
            ...participant,
            name: "You",
          };
        }
        return participant;
      })
      .filter((p) =>
        [
          p?.name?.toLowerCase(),
          p?.email?.toLowerCase(),
          p?.phoneNumber?.toLowerCase(),
        ]
          .join(" ")
          .includes(search)
      );
  }, [participants, user?._id, search]);

  const rows = generateRows(
    updatedParticipants,
    handleChange,
    admin,
    isCreator,
    isPrivate,
    highlight
  );

  return (
    <>
      <Spinner loading={saveLoader || settingLoader} />
      {rows.length === 0 ? (
        <h4>No Participants Found</h4>
      ) : (
        <CustomTable
          rows={rows}
          columns={columns}
          totalWidth={media ? window.innerWidth - 40 : totalWidth}
          admin={admin}
          analyticsEnabled={analyticsEnabled}
          handleChange={(isAdmin, participantId) =>
            handleChange(isAdmin, participantId)
          }
        />
      )}
      {isCreator && (
        <ConfirmationModal
          show={confirmationModal?.show}
          participant={confirmationModal?.participant}
          close={() =>
            setConfirmationModal({
              show: false,
              participant: null,
            })
          }
          confirmOwnershipChange={confirmOwnershipChange}
        />
      )}
    </>
  );
};

export default ParticipantsTable;

const ConfirmationModal = ({
  show,
  close,
  participant,
  confirmOwnershipChange,
}) => {
  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Header className="border-0 mx-auto text-center pt-4">
        <Modal.Title className="font-24 font-bold color-primary">
          Transfer ownership to {participant?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p className="font-15 font-bold color-h text-center">
          You will be the owner until this person accepts ownership. Once this
          person accepts ownership, you will no longer have these photos on your
          account.
        </p>
      </Modal.Body>
      <Modal.Footer className="pb-4 border-0 d-flex justify-content-center">
        <MutedButton title="Cancel" onClick={close} />
        <SecondaryButton title="Confirm" onClick={confirmOwnershipChange} />
      </Modal.Footer>
    </Modal>
  );
};

function generateRows(
  participants,
  handleChange,
  admin,
  isCreator,
  isPrivate,
  highlight
) {
  return (participants || []).map((participant, i) => {
    return {
      name: (
        <div className="d-flex align-items-center">
          <div className="userImage me-2 overflow-hidden d-flex justify-content-center align-items-center">
            <img
              src={
                participant.avatar || "../../../assets/images/icons/user.png"
              }
              alt="avatar"
              width="100%"
            />
          </div>
          {participant.name}
        </div>
      ),
      email: participant?.email || "_",
      phone: participant?.phoneNumber || "_",
      role: (
        <RoleDropdown
          admin={admin}
          participant={participant}
          isCreator={isCreator}
          isPrivate={isPrivate}
          className={highlight && i == 0 ? css.highlight : ""}
          onSelect={(eventKey) => {
            handleChange(eventKey, participant.participantId);
          }}
        />
      ),
    };
  });
}

function RoleDropdown({
  onSelect,
  participant,
  admin,
  isCreator,
  className,
  isPrivate,
}) {
  const { _id: userId } = getLocalStorageUser();
  const { isAdmin, participantId, allPicsAccess } = participant || {};

  const dropDownClassName = cx({
    "no-margin thick-font font-15 d-flex justify-content-center participants-dropdown": true,
    "gray-text": !admin || userId === participantId,
    "bright-blue-text": admin && userId !== participantId,
  });

  const dropdownBorderClassName = cx({
    roleDropdown: true,
    "not-active-border": !admin || userId === participantId,
    "active-border": admin && userId !== participantId,
  });

  return (
    <>
      <Dropdown
        className={dropdownBorderClassName}
        onSelect={(eventKey) => {
          onSelect(eventKey);
        }}
      >
        <Dropdown.Toggle
          id="dropdown-button-dark-example1"
          style={{ width: "70px" }}
          variant="secondary"
          className={className}
        >
          <p className={dropDownClassName}>
            {isAdmin ? (
              "Admin"
            ) : isPrivate ? (
              "Viewer"
            ) : allPicsAccess ? (
              <p className="m-0 lh-1 text-center">
                <span className="font-14 font-bold color-secondary">
                  Viewer
                </span>
                <br />
                <span className="font-11">Full Access</span>
              </p>
            ) : (
              <p className="m-0 lh-1 text-center">
                <span className="font-14 font-bold color-secondary">
                  Viewer
                </span>
                <br />
                <span className="font-11">Partial Access</span>
              </p>
            )}
          </p>
          {admin && userId !== participantId && (
            <img
              src="/assets/images/icons/arrow-down.png"
              alt="drop-down-arrow"
            />
          )}
        </Dropdown.Toggle>

        {admin && userId !== participantId && (
          <Dropdown.Menu variant="light" className="p-0">
            <>
              <Dropdown.Item
                eventKey="Admin"
                className="admin-test"
                active={isAdmin}
              >
                <p className="no-margin thick-font font-15 bright-blue-text">
                  Admin
                </p>
              </Dropdown.Item>
              {isPrivate ? (
                <Dropdown.Item eventKey="Viewer" active={!isAdmin}>
                  <p className="no-margin thick-font font-15 bright-blue-text">
                    Viewer
                  </p>
                </Dropdown.Item>
              ) : (
                <>
                  <Dropdown.Item
                    eventKey="Viewer - Partial Access"
                    active={!isAdmin && !allPicsAccess}
                  >
                    <p className="no-margin thick-font font-15 bright-blue-text">
                      Viewer - Partial Access
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Viewer - Full Access"
                    active={!isAdmin && allPicsAccess}
                  >
                    <p className="no-margin thick-font font-15 bright-blue-text">
                      Viewer - Full Access
                    </p>
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item eventKey="RemoveParticipant">
                <p className="no-margin thick-font font-15 bright-blue-text">
                  Remove Participant
                </p>
              </Dropdown.Item>
              {isCreator && (
                <Dropdown.Item eventKey="TransferOwnership">
                  <p className="no-margin thick-font font-15 bright-blue-text">
                    Transfer Ownership
                  </p>
                </Dropdown.Item>
              )}
            </>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
}
